import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import * as Highcharts from 'highcharts/highmaps';
import { AmplizService } from '../../services/ampliz.service';
import { SearchPhysicianModel } from '../../models/searchPhysicianModel';

@Component({
	selector: 'app-hc-stats',
	templateUrl: './hc-stats.component.html',
	styleUrls: ['./hc-stats.component.css']
})
export class HcStatsComponent implements OnInit {
	@Input() physicianFilters: SearchPhysicianModel;
	loader: boolean = false;
	Highcharts = Highcharts;
	chartConstructor = 'mapChart';
	usaMap = require('@highcharts/map-collection/countries/us/us-all.geo.json');
	mapData: any = [];
	specialityData: any = {
		data: [],
		dataLabel: [],
		loader: false
	};
	providerTypeData: any = {
		data: [],
		dataLabel: [],
		loader: false
	};
	genderData: any = {
		data: [],
		dataLabel: [],
		loader: false
	};
	ageRangeData: any = {
		data: [],
		dataLabel: [],
		loader: false
	};
	totalClaimRangeData: any = {
		data: [],
		dataLabel: [],
		loader: false
	};
	firmTypeData: any = {
		data: [],
		dataLabel: [],
		loader: false
	};
	licenceStateData: any = {
		data: [],
		loader: false
	};
	classificationData: any = {
		data: [],
		dataLabel: [],
		loader: false
	};
	languageData: any = {
		data: [],
		dataLabel: [],
		loader: false
	};
	educationData: any = {
		data: [],
		dataLabel: [],
		loader: false
	};
	cityData: any = {
		data: [],
		dataLabel: [],
		loader: false
	};
	chartOptions: any;

	constructor(private amplizService: AmplizService) {
		this.chartOptions = {
			chart: {
				type: 'bar',
				height: 300,
				toolbar: false
			},
			colors: {
				luminosity: 'light',
				hue: '#0c5a88`',
				count: 30
			},
			plotOptions: {
				bar: {
					horizontal: true
				}
			},
			dataLabels: {
				enabled: false
			}
		};
	}

	specialityChart = {
		series: [{ data: this.specialityData.data, name: '' }],
		xaxis: {
			categories: this.specialityData.dataLabel
		}
	};

	providerTypeChart = {
		series: [{ data: this.providerTypeData.data, name: '' }],
		xaxis: {
			categories: this.providerTypeData.dataLabel
		}
	};

	genderChart = {
		series: [{ data: this.genderData.data, name: '' }],
		xaxis: {
			categories: this.genderData.dataLabel
		}
	};

	ageRangeChart = {
		series: [{ data: this.ageRangeData.data, name: '' }],
		xaxis: {
			categories: this.ageRangeData.dataLabel
		}
	};

	totalClaimRangeChart = {
		series: [{ data: this.totalClaimRangeData.data, name: '' }],
		xaxis: {
			categories: this.totalClaimRangeData.dataLabel
		}
	};

	firmTypeChart = {
		series: [{ data: this.firmTypeData.data, name: '' }],
		xaxis: {
			categories: this.firmTypeData.dataLabel
		}
	};

	licenceStateChart = {
		series: [{ data: this.licenceStateData.data, name: '' }],
		xaxis: {
			categories: this.licenceStateData.dataLabel
		}
	};

	classificationChart = {
		series: [{ data: this.classificationData.data, name: '' }],
		xaxis: {
			categories: this.classificationData.dataLabel
		}
	};

	languageChart = {
		series: [{ data: this.languageData.data, name: '' }],
		xaxis: {
			categories: this.languageData.dataLabel
		}
	};

	educationChart = {
		series: [{ data: this.educationData.data, name: '' }],
		xaxis: {
			categories: this.educationData.dataLabel
		}
	};

	cityChart = {
		series: [{ data: this.cityData.data, name: '' }],
		xaxis: {
			categories: this.cityData.dataLabel
		}
	};

	mapOptions: Highcharts.Options = {
		chart: {
			map: this.usaMap as any
		},
		title: {
			text: ''
		},
		subtitle: {
			text: ''
		},
		mapNavigation: {
			enabled: true,
			buttonOptions: {
				alignTo: 'spacingBox'
			}
		},
		legend: {
			enabled: true
		},
		colorAxis: {
			minColor: '#dce3ff',
			maxColor: '#007bff',
			min: 0
		},
		credits: {
			enabled: false
		},
		series: [
			{
				type: 'map',
				name: '',
				states: {
					hover: {
						color: '#BADA55'
					}
				},
				dataLabels: {
					enabled: true,
					format: '{point.name}'
				},

				allAreas: false,
				data: this.mapData
			}
		]
	};

	licenceStateMapOptions: Highcharts.Options = {
		chart: {
			map: this.usaMap as any,
			zoomType: null
		},
		title: {
			text: ''
		},
		subtitle: {
			text: ''
		},
		mapNavigation: {
			enabled: true,
			buttonOptions: {
				alignTo: 'spacingBox'
			}
		},
		legend: {
			enabled: true
		},
		colorAxis: {
			minColor: '#dce3ff',
			maxColor: '#007bff',
			min: 0
		},
		credits: {
			enabled: false
		},
		series: [
			{
				type: 'map',
				name: '',
				states: {
					hover: {
						color: '#BADA55'
					}
				},
				dataLabels: {
					enabled: true,
					format: '{point.name}'
				},

				allAreas: false,
				data: this.licenceStateData.data
			}
		]
	};

	ngOnInit(): void {
		this.getSpecialityStats();
		this.getPhysiciansForMap();
		this.getProviderTypeStats();
		this.getGenderStats();
		this.getAgeRangeStats();
		this.getTotalClaimsStats();
		this.getFirmTypeStats();
		this.getLicenceStats();
		this.getClassificationStats();
		this.getLanguageStats();
		this.getEducationStats();
		// this.getCityStats();
	}

	getSpecialityStats() {
		this.specialityData.loader = true;
		this.amplizService.getSpecialityStats(this.physicianFilters).subscribe((res) => {
			if (res.length <= 0) return;
			if (res[0].top10.length > 0) {
				res[0].top10?.forEach((item) => {
					this.specialityData.data.push(item.count);
					this.specialityData.dataLabel.push(item.name === null ? '' : item.name);
				});
			}

			if (res[0].others.length > 0) {
				this.specialityData.data.push(res[0].others[0]?.count);
				this.specialityData.dataLabel.push(res[0].others[0]?.name);
			}

			this.specialityData.loader = false;
		});
	}

	getProviderTypeStats() {
		this.providerTypeData.loader = true;
		this.amplizService.getProviderTypeStats(this.physicianFilters).subscribe((res) => {
			if (res.length <= 0) return;
			if (res[0].top10.length > 0) {
				res[0].top10?.forEach((item) => {
					this.providerTypeData.data.push(item.count);
					this.providerTypeData.dataLabel.push(item.name === null ? '' : item.name);
				});
			}

			if (res[0].others.length > 0) {
				this.providerTypeData.data.push(res[0].others[0]?.count);
				this.providerTypeData.dataLabel.push(res[0].others[0]?.name);
			}

			this.providerTypeData.loader = false;
		});
	}

	getGenderStats() {
		this.genderData.loader = true;
		this.amplizService.getGenderStats(this.physicianFilters).subscribe((res) => {
			if (res.length <= 0) return;
			if (res[0].top10.length > 0) {
				res[0].top10?.forEach((item) => {
					this.genderData.data.push(item.count);
					this.genderData.dataLabel.push(item.name === null ? '' : item.name);
				});
			}

			if (res[0].others.length > 0) {
				this.genderData.data.push(res[0].others[0]?.count);
				this.genderData.dataLabel.push(res[0].others[0]?.name);
			}

			this.genderData.loader = false;
		});
	}

	getAgeRangeStats() {
		this.ageRangeData.loader = true;
		this.amplizService.getAgeRangeStats(this.physicianFilters).subscribe((res) => {
			if (res.length <= 0) return;
			if (res[0].top10.length > 0) {
				res[0].top10?.forEach((item) => {
					this.ageRangeData.data.push(item.count);
					this.ageRangeData.dataLabel.push(item.name === null ? '' : item.name);
				});
			}

			if (res[0].others.length > 0) {
				this.ageRangeData.data.push(res[0].others[0]?.count);
				this.ageRangeData.dataLabel.push(res[0].others[0]?.name);
			}

			this.ageRangeData.loader = false;
		});
	}

	getTotalClaimsStats() {
		this.totalClaimRangeData.loader = true;
		this.amplizService.getTotalClaimRangeStats(this.physicianFilters).subscribe((res) => {
			if (res.length <= 0) return;
			if (res[0].top10.length > 0) {
				res[0].top10?.forEach((item) => {
					this.totalClaimRangeData.data.push(item.count);
					this.totalClaimRangeData.dataLabel.push(item.name === null ? '' : item.name);
				});
			}

			if (res[0].others.length > 0) {
				this.totalClaimRangeData.data.push(res[0].others[0]?.count);
				this.totalClaimRangeData.dataLabel.push(res[0].others[0]?.name);
			}

			this.totalClaimRangeData.loader = false;
		});
	}
	getFirmTypeStats() {
		this.firmTypeData.loader = true;
		this.amplizService.getFirmTypeStats(this.physicianFilters).subscribe((res) => {
			if (res.length <= 0) return;
			if (res[0].top10.length > 0) {
				res[0].top10?.forEach((item) => {
					this.firmTypeData.data.push(item.count);
					this.firmTypeData.dataLabel.push(item.name === null ? '' : item.name);
				});
			}

			if (res[0].others.length > 0) {
				this.firmTypeData.data.push(res[0].others[0]?.count);
				this.firmTypeData.dataLabel.push(res[0].others[0]?.name);
			}

			this.firmTypeData.loader = false;
		});
	}
	getLicenceStats() {
		this.licenceStateData.loader = true;
		this.amplizService.getLicenceStateStats(this.physicianFilters).subscribe((res) => {
			if (res.length <= 0) return;
			res.forEach((item) => {
				const values = [`us-${item.licenses_state.toLowerCase()}`, item.count];
				this.licenceStateData.data.push(values);
			});

			this.licenceStateData.loader = false;
		});
	}

	getClassificationStats() {
		this.classificationData.loader = true;
		this.amplizService.getClassificationStats(this.physicianFilters).subscribe((res) => {
			if (res.length <= 0) return;
			if (res[0].top10.length > 0) {
				res[0].top10?.forEach((item) => {
					this.classificationData.data.push(item.count);
					this.classificationData.dataLabel.push(item.name === null ? '' : item.name);
				});
			}

			if (res[0].others.length > 0) {
				this.classificationData.data.push(res[0].others[0]?.count);
				this.classificationData.dataLabel.push(res[0].others[0]?.name);
			}

			this.classificationData.loader = false;
		});
	}

	getLanguageStats() {
		this.languageData.loader = true;
		this.amplizService.getLanguagesStats(this.physicianFilters).subscribe((res) => {
			if (res.length <= 0) return;
			if (res[0].top10.length > 0) {
				res[0].top10?.forEach((item) => {
					this.languageData.data.push(item.count);
					this.languageData.dataLabel.push(item.name === null ? '' : item.name);
				});
			}

			if (res[0].others.length > 0) {
				this.languageData.data.push(res[0].others[0]?.count);
				this.languageData.dataLabel.push(res[0].others[0]?.name);
			}

			this.languageData.loader = false;
		});
	}

	getEducationStats() {
		this.educationData.loader = true;
		this.amplizService.getEducationStats(this.physicianFilters).subscribe((res) => {
			if (res.length <= 0) return;
			if (res[0].top10.length > 0) {
				res[0].top10?.forEach((item) => {
					this.educationData.data.push(item.count);
					this.educationData.dataLabel.push(item.name === null ? '' : item.name);
				});
			}

			if (res[0].others.length > 0) {
				this.educationData.data.push(res[0].others[0]?.count);
				this.educationData.dataLabel.push(res[0].others[0]?.name);
			}

			this.educationData.loader = false;
		});
	}

	getCityStats() {
		this.cityData.loader = true;
		this.amplizService.getCityStats(this.physicianFilters).subscribe((res) => {
			if (res.length <= 0) return;
			if (res[0].top10.length > 0) {
				res[0].top10?.forEach((item) => {
					this.cityData.data.push(item.count);
					this.cityData.dataLabel.push(item.name === null ? '' : item.name);
				});
			}

			if (res[0].others.length > 0) {
				this.cityData.data.push(res[0].others[0]?.count);
				this.cityData.dataLabel.push(res[0].others[0]?.name);
			}

			this.cityData.loader = false;
		});
	}

	getPhysiciansForMap() {
		this.loader = true;

		this.amplizService.getPhysiciansForMap(this.physicianFilters).subscribe((res) => {
			if (res.length <= 0) return;
			res.forEach((item) => {
				const values = [`us-${item.state.toLowerCase()}`, item.count];
				this.mapData.push(values);
			});

			this.loader = false;
		});
	}
}
