import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-npi-data-progress-modal",
  templateUrl: "./npi-data-progress-modal.component.html",
  styleUrls: ["./npi-data-progress-modal.component.css"],
})
export class NpiDataProgressModalComponent implements OnInit {
  @Input() name: string = "";
  constructor() {}

  ngOnInit() {}
  bookDemo() {
    window.open('https://calendar.app.google/3FSMye6AToUL5zBa7', '_blank');
  }
}
