<div
  class="action-container"
  #menuTrigger="matMenuTrigger"
  [matMenuTriggerFor]="menu"
  *ngIf="downloadOptions.length !== 0"
>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
  >
    <path
      d="M15.75 11.25V14.25C15.75 14.6478 15.592 15.0294 15.3107 15.3107C15.0294 15.592 14.6478 15.75 14.25 15.75H3.75C3.35218 15.75 2.97064 15.592 2.68934 15.3107C2.40804 15.0294 2.25 14.6478 2.25 14.25V11.25"
      stroke="#0c5a88"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M5.25 7.5L9 11.25L12.75 7.5"
      stroke="#0c5a88"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M9 11.25V2.25"
      stroke="#0c5a88"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</div>

<mat-menu class="dropdown" #menu="matMenu" xPosition="after">
  <button
    *ngFor="let option of downloadOptions"
    mat-menu-item
    (click)="download(option)"
  >
    {{ downloadData[option] }}
  </button>
</mat-menu>
