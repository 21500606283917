<div id="wrapper" style="height: 100%">
	<app-header [user]="" style="width: 100%"></app-header>

	<div class="content-page" style="height: 100%">
		<div class="enlarged">
			<div class="d-flex align-items-center justify-content-between mt-2 mb-4">
				<h4 class="title-heading">User Management</h4>
				<button type="button" class="btn btn-lg create-btn pull-right" (click)="handleDialog()">
					<mat-icon>add</mat-icon>
					Create New User
				</button>
			</div>
			<div class="table-container">
				<table mat-table [dataSource]="dataSource" class="mat-elevation-z2">
					<!-- Name Column -->
					<ng-container matColumnDef="name">
						<th mat-header-cell *matHeaderCellDef>Name</th>
						<td mat-cell *matCellDef="let user">{{ user.fullName }}</td>
					</ng-container>

					<!-- Company Column -->
					<ng-container matColumnDef="companyName">
						<th mat-header-cell *matHeaderCellDef>Company Name</th>
						<td mat-cell *matCellDef="let user">{{ user.companyName }}</td>
					</ng-container>

					<!-- Current Credit Column -->
					<ng-container matColumnDef="currentCredit">
						<th mat-header-cell *matHeaderCellDef>Current Credit</th>
						<td mat-cell *matCellDef="let user">{{ user.currentCredits }}</td>
					</ng-container>

					<!-- Mobile Credit Column -->
					<ng-container matColumnDef="mobileCredit">
						<th mat-header-cell *matHeaderCellDef>Mobile Credit</th>
						<td mat-cell *matCellDef="let user">{{ user.mobileCredits }}</td>
					</ng-container>

					<!-- Subscription Type Column -->
					<ng-container matColumnDef="subscriptionType">
						<th mat-header-cell *matHeaderCellDef>Subscription Type</th>
						<td mat-cell *matCellDef="let user">{{ user.subscriptionType }}</td>
					</ng-container>

					<!-- Is Active Column -->
					<ng-container matColumnDef="active">
						<th mat-header-cell *matHeaderCellDef>Active</th>
						<td mat-cell *matCellDef="let user">{{ user.isActive ? 'Active' : 'Not Active' }}</td>
					</ng-container>

					<!-- Actions Column -->
					<ng-container matColumnDef="action">
						<th mat-header-cell *matHeaderCellDef>Actions</th>
						<td mat-cell *matCellDef="let user">
							<button
								class="edit-btn d-flex"
								mat-icon-button
								color="primary"
								(click)="handleDialog(true, user.userID)"
							>
								<mat-icon [ngStyle]="{ color: '#0c5a88' }">edit</mat-icon>
							</button>
						</td>
					</ng-container>

					<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
					<tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
				</table>

				<!-- <mat-paginator
					[pageSizeOptions]="[5, 10, 25]"
					showFirstLastButtons
					(page)="onPageChange($event)"
				></mat-paginator> -->

				<div class="paginationWrapper d-flex align-center justify-content-end" *ngIf="totalCount > 0">
					<div class="pull-right paginationInfo mr-2 mt-2">
						{{ offset }}-{{ count }} of
						{{ totalCount }}
					</div>
					<ul class="pagination">
						<li
							[ngClass]="{
								disabled: pager.currentPage === 1
							}"
						>
							<a *ngIf="pager.currentPage === 1" style="border-radius: 5px 0 0 5px !important"
								><i class="fa fa-chevron-left" aria-hidden="true"></i
							></a>
							<a
								*ngIf="pager.currentPage !== 1"
								(click)="onPaginationClick(pager.currentPage - 1)"
								style="border-radius: 5px 0 0 5px !important"
								><i class="fa fa fa-chevron-left" aria-hidden="true"></i
							></a>
						</li>
						<li
							[ngClass]="{
								disabled: pager.currentPage === pager.totalPages
							}"
						>
							<a
								*ngIf="pager.currentPage === pager.totalPages"
								style="border-radius: 0 5px 5px 0 !important"
								><i class="fa fa-chevron-right" aria-hidden="true"></i
							></a>
							<a
								*ngIf="pager.currentPage !== pager.totalPages"
								(click)="onPaginationClick(pager.currentPage + 1)"
								style="border-radius: 0 5px 5px 0 !important"
								><i class="fa fa-chevron-right" aria-hidden="true"></i
							></a>
						</li>
					</ul>
				</div>
			</div>
		</div>
	</div>

	<!-- Dialog Box Starts -->

	<ng-template let-data #userDialog>
		<div class="d-flex justify-content-between align-items-center">
			<h4 class="title-heading">{{ data.isEditMode ? 'Update User' : 'Create User' }}</h4>
			<mat-icon class="close-icon" (click)="onCancel()">close</mat-icon>
		</div>
		<hr class="divider" />
		<form
			[formGroup]="editForm"
			(ngSubmit)="onSubmit(true)"
			class="form-wrapper"
			*ngIf="data.isEditMode"
		>
			<mat-form-field appearance="fill">
				<mat-label>Full Name </mat-label>
				<input matInput formControlName="fullname" />
				<mat-error *ngIf="editForm.get('fullname')?.hasError('required')">
					Full Name is required.
				</mat-error>
			</mat-form-field>

			<mat-form-field appearance="fill">
				<mat-label>Company Name</mat-label>
				<input matInput formControlName="companyName" />
				<mat-error *ngIf="editForm.get('companyName')?.hasError('required')">
					Company Name is required.
				</mat-error>
			</mat-form-field>

			<mat-form-field appearance="fill">
				<mat-label>Phone Number</mat-label>
				<input matInput formControlName="phoneNumber" type="number" />
				<mat-error *ngIf="editForm.get('phoneNumber')?.hasError('required')">
					Phone Number is required.
				</mat-error>
			</mat-form-field>

			<mat-form-field appearance="fill">
				<mat-label>Active</mat-label>
				<mat-select formControlName="isActive">
					<mat-option [value]="true">Yes</mat-option>
					<mat-option [value]="false">No</mat-option>
				</mat-select>
				<mat-error *ngIf="editForm.get('isActive')?.hasError('required')">
					Active is required.
				</mat-error>
			</mat-form-field>

			<mat-form-field appearance="fill">
				<mat-label>Is Paid</mat-label>
				<mat-select formControlName="subscriptionType">
					<mat-option [value]="'Free'">Free</mat-option>
					<mat-option [value]="'Paid'">Paid</mat-option>
				</mat-select>
				<mat-error *ngIf="editForm.get('isPaid')?.hasError('required')">
					Is Paid is required.
				</mat-error>
			</mat-form-field>

			<mat-form-field appearance="fill">
				<mat-label>Current Credit</mat-label>
				<input matInput formControlName="currentCredit" type="number" min="0" />
				<mat-error *ngIf="editForm.get('currentCredit')?.hasError('required')">
					Current Credit is required.
				</mat-error>
			</mat-form-field>

			<mat-form-field appearance="fill">
				<mat-label>Current Mobile Credit</mat-label>
				<input matInput formControlName="mobileCredit" type="number" min="0" />
				<mat-error *ngIf="editForm.get('mobileCredit')?.hasError('required')">
					Mobile Credit is required.
				</mat-error>
			</mat-form-field>

			<mat-form-field appearance="fill">
				<mat-label>Subscription Name</mat-label>
				<input matInput formControlName="subscriptionName" />
				<mat-error *ngIf="editForm.get('subscriptionName')?.hasError('required')">
					Subscription Name is required.
				</mat-error>
			</mat-form-field>

			<mat-form-field appearance="fill">
				<mat-label>Subscription Credit</mat-label>
				<input matInput formControlName="subscriptionCredits" type="number" min="0" />
				<mat-error *ngIf="editForm.get('subscriptionCredits')?.hasError('required')">
					Subscription Credit is required.
				</mat-error>
			</mat-form-field>

			<mat-form-field appearance="fill">
				<mat-label>Subscription Mobile Credit</mat-label>
				<input matInput formControlName="subscriptionMobileCredits" type="number" min="0" />
				<mat-error *ngIf="editForm.get('subscriptionMobileCredits')?.hasError('required')">
					Subscription Mobile Credit is required.
				</mat-error>
			</mat-form-field>

			<mat-form-field appearance="fill">
				<mat-label>Subscription Active</mat-label>
				<mat-select formControlName="subscriptionIsActive">
					<mat-option [value]="true">Yes</mat-option>
					<mat-option [value]="false">No</mat-option>
				</mat-select>
				<mat-error *ngIf="editForm.get('subscriptionIsActive')?.hasError('required')">
					Active is required.
				</mat-error>
			</mat-form-field>

			<div class="action-btn-container" mat-dialog-actions align="end">
				<button mat-button type="button" (click)="onCancel()" class="action-btn cancel-btn mr-2">
					Cancel
				</button>
				<button
					mat-button
					type="submit"
					[class.disabled-btn]="editForm.invalid"
					[class.disable-click]="btnLoader"
					class="action-btn submit-btn"
				>
					<span class="loader" *ngIf="btnLoader"></span>
					<span *ngIf="!btnLoader">Update</span>
				</button>
			</div>
		</form>

		<form
			[formGroup]="createForm"
			(ngSubmit)="onSubmit(false)"
			class="form-wrapper"
			*ngIf="!data.isEditMode"
		>
			<mat-form-field appearance="fill">
				<mat-label>Full Name </mat-label>
				<input matInput formControlName="fullname" />
				<mat-error *ngIf="createForm.get('fullname')?.hasError('required')">
					Full Name is required.
				</mat-error>
			</mat-form-field>

			<mat-form-field appearance="fill">
				<mat-label>Password</mat-label>
				<input matInput formControlName="password" />
				<mat-error *ngIf="createForm.get('password')?.hasError('required')">
					Password is required.
				</mat-error>
			</mat-form-field>

			<mat-form-field appearance="fill">
				<mat-label>Company Name</mat-label>
				<input matInput formControlName="companyName" />
				<mat-error *ngIf="createForm.get('companyName')?.hasError('required')">
					Company Name is required.
				</mat-error>
			</mat-form-field>

			<mat-form-field appearance="fill">
				<mat-label>Email</mat-label>
				<input matInput formControlName="email" />
				<mat-error *ngIf="createForm.get('email')?.hasError('required')">
					Email is required.
				</mat-error>
			</mat-form-field>

			<mat-form-field appearance="fill">
				<mat-label>Phone Number</mat-label>
				<input matInput formControlName="phoneNumber" type="number" />
				<mat-error *ngIf="createForm.get('phoneNumber')?.hasError('required')">
					Phone Number is required.
				</mat-error>
			</mat-form-field>

			<mat-form-field appearance="fill">
				<mat-label>Is Paid</mat-label>
				<mat-select formControlName="isPaid">
					<mat-option [value]="'Free'">Free</mat-option>
					<mat-option [value]="'Paid'">Paid</mat-option>
				</mat-select>
				<mat-error *ngIf="createForm.get('isPaid')?.hasError('required')">
					Is Paid is required.
				</mat-error>
			</mat-form-field>

			<mat-form-field appearance="fill">
				<mat-label>Is Active</mat-label>
				<mat-select formControlName="subscriptionIsActive">
					<mat-option [value]="true">Yes</mat-option>
					<mat-option [value]="false">No</mat-option>
				</mat-select>
				<mat-error *ngIf="createForm.get('subscriptionIsActive')?.hasError('required')">
					Active is required.
				</mat-error>
			</mat-form-field>

			<mat-form-field appearance="fill">
				<mat-label>Current Credit</mat-label>
				<input matInput formControlName="currentCredit" type="number" min="0" />
				<mat-error *ngIf="createForm.get('currentCredit')?.hasError('required')">
					Current Credit is required.
				</mat-error>
			</mat-form-field>

			<mat-form-field appearance="fill">
				<mat-label>Current Mobile Credit</mat-label>
				<input matInput formControlName="mobileCredit" type="number" min="0" />
				<mat-error *ngIf="createForm.get('mobileCredit')?.hasError('required')">
					Mobile Credit is required.
				</mat-error>
			</mat-form-field>

			<mat-form-field appearance="fill">
				<mat-label>Subscription Name</mat-label>
				<input matInput formControlName="subscriptionName" />
				<mat-error *ngIf="createForm.get('subscriptionName')?.hasError('required')">
					Subscription Name is required.
				</mat-error>
			</mat-form-field>

			<mat-form-field appearance="fill">
				<mat-label>Subscription Amount</mat-label>
				<input matInput formControlName="subscriptionAmount" type="number" min="0" />
				<mat-error *ngIf="createForm.get('subscriptionAmount')?.hasError('required')">
					Subscription Amount is required.
				</mat-error>
			</mat-form-field>

			<mat-form-field appearance="fill">
				<mat-label>Subscription Credits</mat-label>
				<input matInput formControlName="subscriptionCredits" type="number" min="0" />
				<mat-error *ngIf="createForm.get('subscriptionCredits')?.hasError('required')">
					Subscription Credit is required.
				</mat-error>
			</mat-form-field>

			<mat-form-field appearance="fill">
				<mat-label>Subscription Mobile Credit</mat-label>
				<input matInput formControlName="subscriptionMobileCredits" type="number" min="0" />
				<mat-error *ngIf="createForm.get('subscriptionMobileCredits')?.hasError('required')">
					Subscription Mobile Credit is required.
				</mat-error>
			</mat-form-field>

			<mat-form-field appearance="fill">
				<mat-label>Subscription Active</mat-label>
				<mat-select formControlName="subscriptionIsActive">
					<mat-option [value]="true">Yes</mat-option>
					<mat-option [value]="false">No</mat-option>
				</mat-select>
				<mat-error *ngIf="createForm.get('subscriptionIsActive')?.hasError('required')">
					Active is required.
				</mat-error>
			</mat-form-field>

			<div class="action-btn-container" mat-dialog-actions align="end">
				<button mat-button type="button" (click)="onCancel()" class="action-btn cancel-btn mr-2">
					Cancel
				</button>
				<button
					mat-button
					type="submit"
					[class.disabled-btn]="data.isEditMode ? editForm.invalid : createForm.invalid"
					[class.disable-click]="btnLoader"
					class="action-btn submit-btn"
				>
					<span class="loader" *ngIf="btnLoader"></span>
					<span *ngIf="!btnLoader">Create</span>
				</button>
			</div>
		</form>
	</ng-template>
</div>
