import {
	Component,
	OnInit,
	ElementRef,
	ViewChild,
	Output,
	EventEmitter,
	Input,
	OnChanges,
	NgZone,
	Renderer2,
	TemplateRef,
	SimpleChanges
} from '@angular/core';
import { MatChipInputEvent } from '@angular/material/chips';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { COMMA, E, ENTER, P } from '@angular/cdk/keycodes';
import { FormControl, UntypedFormControl } from '@angular/forms';
import { AmplizService } from 'src/app/modules/healthcare/services/ampliz.service';
import {
	MatAutocompleteSelectedEvent,
	MatAutocompleteTrigger
} from '@angular/material/autocomplete';
import { MatSelectChange } from '@angular/material/select';
import { BehaviorSubject, Observable } from 'rxjs';
import { finalize, debounceTime } from 'rxjs/operators';
import { FilterStorageService } from '../../../services/filter-storage.service';
import { AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from '../../../services/data.service';
import { LoaderService } from 'src/app/modules/healthcare/services/loader.service';
import { MessageService } from 'src/app/modules/B2B/services/message.service';
import { MatDialog } from '@angular/material/dialog';
import { SearchPhysicianModel } from '../../../models/searchPhysicianModel';
// import { filter, least, tickStep } from 'd3';
import { isEqual, cloneDeep } from 'lodash';
import { SkeletonloaderService } from 'src/app/modules/healthcare/services/skeletonloader.service';
import { StaticDropdownService } from 'src/app/modules/healthcare/services/static-dropdown.service';
import { debounce } from 'lodash';

@Component({
	selector: 'app-filter-physician',
	templateUrl: './filter-physician.component.html',
	styleUrls: ['./filter-physician.component.css']
})
export class FilterPhysicianComponent implements OnInit, AfterViewInit, OnChanges {
	@Output() onFilterChange = new EventEmitter<any>();
	@Output() filterCleared = new EventEmitter<any>();
	@Output() onSaveSearch = new EventEmitter<any>();
	@Input() isSubscribed: boolean;
	@Input() leadWithEmail: boolean = false;
	emailTypeIsp: boolean = false;
	selectable = true;
	removable = true;
	@ViewChild('hospitalNameInput', { static: false })
	hospitalNameInput: ElementRef<HTMLInputElement>;
	@ViewChild('npiNumberInput', { static: false })
	npiNumberInput: ElementRef<HTMLInputElement>;
	@ViewChild('experienceInput', { static: false })
	experienceInput: ElementRef<HTMLInputElement>;
	@ViewChild('cptCodeInput', { static: false })
	cptCodeInput: ElementRef<HTMLInputElement>;
	@ViewChild('stateInput', { static: false })
	stateInput: ElementRef<HTMLInputElement>;
	@ViewChild('cityInput', { static: false })
	cityInput: ElementRef<HTMLInputElement>;
	@ViewChild('includeSpecialityInput', { static: false })
	includeSpecialityInput: ElementRef<HTMLInputElement>;
	@ViewChild('excludeSpecialityInput', { static: false })
	excludeSpecialityInput: ElementRef<HTMLInputElement>;
	// @ViewChild('emailStatusInput', { static: false })
	// emailStatusInput: ElementRef<HTMLInputElement>;
	@ViewChild('codeClassificationInput', { static: false })
	codeClassificationInput: ElementRef<HTMLInputElement>;
	@ViewChild('languagesInput', { static: false })
	languagesInput: ElementRef<HTMLInputElement>;
	@ViewChild('languagesInput', { static: false })
	ageInput: ElementRef<HTMLInputElement>;
	separatorKeysCodes: number[] = [ENTER, COMMA];
	hospitalControl = new FormControl();
	stateControl = new FormControl();
	typedLanguage = '';
	cityControl = new FormControl();
	npiNumberControl = new FormControl();
	experienceControl = new FormControl();
	includeSpecialityControl = new FormControl();
	D_includeSpecialityControl = new FormControl();
	excludeSpecialityControl = new FormControl();
	emailStatusControl = new FormControl();
	codeClassificationControl = new FormControl();
	languagesControl = new FormControl();
	ageControl = new FormControl();
	cptControl = new FormControl();
	bsnList = ['RN with BSN but no MSN', 'RN without BSN'];
	certificatesOptions = ['Yes', 'No'];

	c_specialityGroupControl = new FormControl();
	c_specialityControl = new FormControl();
	c_subSpecialityControl = new FormControl();

	hideHospitalNamePlaceholder: boolean = false;
	staticCodeClassificationList = ['CPT', 'HSCPC'];
	// Data for filter component
	filteredHospitals: Observable<string[]>;
	filteredStates: Observable<string[]>;
	filteredCodes: number[] = [];
	stateList: any = [];
	filteredCities: any = [];
	filteredSpeciality: any = [];
	filteredSpecialityEx: any = [];
	cptCodeList: any = [];
	hscpcCodeList: any = [];

	// Variable to be used for filter API call
	includedSpecialities: any = [];
	excludedSpecialities: any = [];
	emailStatus: number = 0;
	selectedCPTCode: any = [];
	selectedHSCPCCode: any = [];
	languages: string[] = [];
	age: string = '';
	physicianName: string = '';
	hospitalNames: any = [];
	selectedStates: any = [];
	selectedCities: any = [];
	searchCity: any = [];
	npiNumberList: any = [];
	experienceList: any = [];
	npiNumbers: any = [];
	experience: any = [];
	suggestionTerms: any = [];
	suggestionTermsEx: any = [];
	isPaid: boolean = false;
	provider_Type: any;
	leadWithProvider: boolean = false;

	hospitalFirmType: any = this.hcpDropdown.HCP_FILTERS['hospital_firm_type'];
	hospitalClassification: any = this.hcpDropdown.HCP_FILTERS['hospital_classification'];

	keywordList: any = [];

	// More Filter Var
	dialogRef: any;
	tempDialogFilters: SearchPhysicianModel = new SearchPhysicianModel();
	filterData: SearchPhysicianModel = new SearchPhysicianModel();
	saveSearchFilter: SearchPhysicianModel = new SearchPhysicianModel();

	cloneTempDialogFilters: any;
	dialogState: boolean = false;

	moreFilterRecordCount: BehaviorSubject<any> = new BehaviorSubject<any>(null);

	// recordCountLoader: boolean = false;
	recordCountLoader: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

	showConfirmDialog: boolean = false;
	d_privatePractioner: boolean = false;
	d_personalEmail: boolean = false;
	d_advanceFilterLength: number = 0;
	d_teleHealth: boolean = false;

	c_specialityGroup: any = [
		{
			id: '65702c3a6e32a09c8f4d256a',
			physicianCount: 234515,
			specialityType: 'Dentist'
		},
		{
			id: '65702c3a6e32a09c8f4d256b',
			physicianCount: 230253,
			specialityType: 'Pharmacists/Pharmacies'
		},
		{
			id: '65702c3a6e32a09c8f4d256c',
			physicianCount: 111188,
			specialityType: 'Technologists/Technicians'
		},
		{
			id: '65702c3a6e32a09c8f4d256d',
			physicianCount: 101,
			specialityType: 'Executive'
		},
		{
			id: '65702c3a6e32a09c8f4d256e',
			physicianCount: 453201,
			specialityType: 'Therapist'
		},
		{
			id: '65702c3a6e32a09c8f4d256f',
			physicianCount: 246140,
			specialityType: 'Physician Assistant'
		},
		{
			id: '65702c3a6e32a09c8f4d2570',
			physicianCount: 694570,
			specialityType: 'Nurse'
		},
		{
			id: '65702c3a6e32a09c8f4d2571',
			physicianCount: 1482419,
			specialityType: 'Healthcare Professional'
		},
		{
			id: '65702c3a6e32a09c8f4d2572',
			physicianCount: 1177276,
			specialityType: 'Physician'
		}
	];
	c_specialityList: any = [];
	c_subSpecialityList: any = [];

	ageList: any = this.hcpDropdown.HCP_FILTERS['ageList'];
	languageList: any = [];
	ehrProductClassification: any = this.hcpDropdown.HCP_FILTERS['ehr_product_classification'];
	ehrProductNameList: any = [];
	totalClaimsRange: any = this.hcpDropdown.HCP_FILTERS['number_of_claims'];

	@ViewChild('filterDialog') filterDialog = {} as TemplateRef<any>;

	@ViewChild('zipInput')
	zipInput: ElementRef<HTMLInputElement>;
	zipControl = new UntypedFormControl();

	zipCodeList: any = [];

	zipCodeRadius: string[] = [
		'Within 25 miles',
		'Within 50 miles',
		'Within 100 miles',
		'Within 300 miles'
	];
	selectedRadius: string = '25';
	selectedZipCodeForRadius: string = '';

	allowedEmailsForZipcodeRadius = [
		'guru@ampliz.com',
		'Andy.Olson@OptigyGroup.com',
		'bhavini.scianablo@optigygroup.com',
		'jonathan.isaacks@optigygroup.com',
		'dave.ciccone@optigygroup.com',
		'teja@ampliz.com',
		'tanay123@gmail.com',
		'charles.berry@lakeb2b.com'
	];

	filteredResidencyHospitalList: any = [];
	filteredFellowshipList: any = [];
	filteredMedicalSchoolsList: any = [];

	educationStateList: any = [];

	constructor(
		private amplizService: AmplizService,
		private filterStorageService: FilterStorageService,
		private ngZone: NgZone,
		public router: Router,
		private healthCareDataService: DataService,
		private loaderService: LoaderService,
		private messageService: MessageService,
		private renderer: Renderer2,
		private elementRef: ElementRef,
		private dialog: MatDialog,
		private gridLoader: SkeletonloaderService,
		private hcpDropdown: StaticDropdownService
	) {
		this.getEducationFilterList = debounce(this.getEducationFilterList, 300);
		this.healthCareDataService.saveSearchFilter.subscribe((filters: SearchPhysicianModel) => {
			if (Object.keys(filters).length > 0) {
				this.saveFilterApplied(filters);
				this.healthCareDataService.saveSearchFilter.next({}); //clear after storing the data
			}
		});
		this.healthCareDataService.physicianSearchFromPhysicianGroup.subscribe((hospitalValue) => {
			if (hospitalValue !== '') {
				this.hospitalNames = [hospitalValue];
				this.omitChange();
			}
		});
		this.healthCareDataService.physicianSearchFromGlobalSearch.subscribe((speciality) => {
			if (speciality !== '') {
				this.includedSpecialities = [speciality];
				this.omitChange();
			}
		});
	}
	get localstorageHospitalName() {
		return window.localStorage.getItem('hospitalName') || '';
	}

	get hospitalExpandData() {
		return [
			...this.hospitalNames,
			this.filterData.firmtype,
			...this.filterData.classification
		].filter(Boolean);
	}

	get moreFilterHospitalData() {
		return [
			...this.tempDialogFilters.hospitalNameList,
			this.tempDialogFilters.firmtype,
			...this.tempDialogFilters.classification
		].filter(Boolean);
	}

	get ehrSelectedData() {
		return [
			...this.tempDialogFilters.ehrproductname,
			...this.tempDialogFilters.productclassification
		];
	}

	get showSaveSearch() {
		return this.isFiltersApplied();
	}

	get selectedCertificatesData() {
		const arr = [];
		if (this.tempDialogFilters.abmsabps !== '') {
			arr.push(this.tempDialogFilters.abmsabps);
		}
		if (this.tempDialogFilters.abom !== '') {
			arr.push(this.tempDialogFilters.abom);
		}
		return arr;
	}

	get isZipCodeRadius() {
		const isAllowed = this.allowedEmailsForZipcodeRadius.includes(localStorage.getItem('email_id'));
		return isAllowed;
	}

	get educationFilter() {
		return [
			...this.tempDialogFilters.residencyhospitals,
			...this.tempDialogFilters.fellowships,
			...this.tempDialogFilters.medicalschools
		];
	}
	get educationStateFilter() {
		return [
			...this.tempDialogFilters.undergraduate_state,
			...this.tempDialogFilters.medicalschool_state,
			...this.tempDialogFilters.residency_state,
			...this.tempDialogFilters.fellowship_state
		];
	}

	// get isFilterApplied() {
	// 	const filters = this.dialogRef !== undefined ? this.filterData : this.tempDialogFilters;

	// 	return (
	// 		filters.specialityIncluded.length > 0 ||
	// 		filters.specialityExcluded.length > 0 ||
	// 		filters.speciality.length > 0 ||
	// 		filters.abmsabps.length > 0 ||
	// 		filters.abom.length > 0 ||
	// 		filters.abom.length > 0 ||
	// 		filters.specialityIncluded.length > 0 ||
	// 		filters.specialityIncluded.length > 0 ||
	// 	)
	// }
	// isFilterApplied() {
	// 	// Iterate over all keys in the object
	// 	const obj = this.dialogRef !== undefined ? this.filterData : this.tempDialogFilters;
	// 	for (let key in obj) {
	// 		if (obj.hasOwnProperty(key)) {
	// 			const value = obj[key];

	// 			if (Array.isArray(value) && value.length > 0) {
	// 				console.log('true');

	// 				return true;
	// 			}

	// 			if (typeof value === 'boolean' && value === true) {
	// 				console.log('true');

	// 				return true;
	// 			}

	// 			if (typeof value === 'string' && value.length > 0) {
	// 				console.log('true');

	// 				return true;
	// 			}
	// 		}
	// 	}
	// 	console.log('false');
	// 	// If none of the conditions are met, return false
	// 	return false;
	// }
	ngOnInit() {
		// this.getPersistData();
		this.getAllListData();
		this.getHSCPCCodes();
		this.getCPTCodes();
	}
	get selectedCodes() {
		return [...this.selectedCPTCode, ...this.selectedHSCPCCode];
	}
	ngAfterViewInit() {
		if (this.localstorageHospitalName) {
			this.hospitalNames.push(this.localstorageHospitalName);
			window.localStorage.removeItem('hospitalName');
		}
		this.getPersistData();
	}
	ngOnChanges() {
		this.isPaid = !this.isSubscribed;
		this.changeSearchData();
	}

	// get getSelectedLanguages() {
	// 	return this.languages;
	// }
	getPersistData() {
		setTimeout(async () => {
			this.hospitalNames = this.filterStorageService.get('physician_hospital') || [];
			// this.emailStatus = this.filterStorageService.get('email_Score') || 0;
			this.selectedCPTCode = this.filterStorageService.get('physician_selectedCPTCode') || [];
			this.selectedHSCPCCode = this.filterStorageService.get('physician_selectedHSCPCCode') || [];
			this.includedSpecialities = this.filterStorageService.get('physician_specialityIncluded') || [];
			this.excludedSpecialities = this.filterStorageService.get('physician_specialityExcluded') || [];
			this.filteredCodes = this.filterStorageService.get('selected_code_list') || [];
			this.physicianName = this.filterStorageService.get('physician_physicianName') || '';
			this.filterData.cityList = this.filterStorageService.get('physician_cityList') || [];
			this.filterData.zipcode = this.filterStorageService.get('physician_zipCode') || [];
			this.filterData.stateList = this.filterStorageService.get('physician_stateList') || [];
			this.npiNumbers = this.filterStorageService.get('physician_npiNumber') || [];
			this.leadWithEmail = this.filterStorageService.get('physician_leadWithEmail') || false;
			this.filterData.classification = this.filterStorageService.get('physician_classification') || [];
			this.filterData.firmtype = this.filterStorageService.get('physician_firmType') || '';
			this.tempDialogFilters.ehrproductname =
				this.filterStorageService.get('physician_ehrProductName') || [];
			this.tempDialogFilters.productclassification =
				this.filterStorageService.get('physician_ehrProductClassification') || [];
			this.tempDialogFilters.totalclaimsrange =
				this.filterStorageService.get('physician_totalClaimsRange') || [];

			await this.action_language('fetch');
			await this.action_advanceFilter('fetch');
			await this.action_classificationSpeciality('fetch');
			await this.action_ageRangeList('fetch');
			await this.action_emailStatus('fetch');
			await this.action_procedureCodes('fetch');
			await this.action_expirence('fetch');
			await this.action_teleHealth('fetch');
			await this.action_zipCodeByRadius('fetch');
			await this.action_education('fetch');
			await this.actionEducationLocation('fetch');

			this.tempDialogFilters.licenses_state =
				this.filterStorageService.get('physician_stateLicences') || [];

			if (
				this.hospitalNames.length > 0 ||
				this.includedSpecialities.length > 0 ||
				this.excludedSpecialities.length > 0 ||
				this.physicianName !== '' ||
				this.selectedCities.length > 0 ||
				this.selectedStates.length > 0 ||
				this.npiNumbers.length > 0 ||
				this.emailStatus !== undefined ||
				this.leadWithEmail !== false ||
				this.leadWithProvider !== false ||
				// Advance Filters
				this.tempDialogFilters.experience.length > 0 ||
				this.tempDialogFilters.cptCodes.length > 0 ||
				this.tempDialogFilters.hcpcsCodes.length > 0 ||
				this.tempDialogFilters.languages.length > 0 ||
				this.tempDialogFilters.emailTypeIsp !== '' ||
				this.tempDialogFilters.ageRangeList.length > 0 ||
				this.tempDialogFilters.provider_Type !== '' ||
				this.tempDialogFilters.specialityGroup !== '' ||
				this.tempDialogFilters.speciality.length > 0 ||
				this.tempDialogFilters.subSpeciality.length > 0 ||
				this.tempDialogFilters.istelehealth !== '' ||
				this.tempDialogFilters.radiusZipcodes.length > 0
			) {
				this.omitChange();
			}
		});
	}
	get getExperienceList() {
		return this.experienceList;
	}

	get filteredLanguageList() {
		return this.languageList;
	}

	getDropDown(filterName: any) {
		return this.hcpDropdown.HCP_FILTERS[filterName];
	}

	onCitySelect(city: any) {
		const found = this.selectedCities.findIndex((ele) => ele.cityId === city.cityId);

		if (found !== -1) {
			// this.selectedCities = this.selectedCities.filter(
			//   (ele) => ele.cityId !== city.cityId
			// );
		} else {
			this.selectedCities.push(city);
			this.cityInput.nativeElement.value = '';
			this.cityControl.setValue(null);
		}
		this.omitChange();
	}
	onCityDeselect(city: any) {
		this.selectedCities = this.selectedCities.filter((ele) => ele.cityId !== city.cityId);
		this.omitChange();
	}
	public openItem(path: string): void {
		this.ngZone.run(() => this.router.navigateByUrl(path)).then();
	}
	onNPISelect(npi: any) {
		const found = this.npiNumbers.findIndex((ele) => ele === npi);
		if (found !== -1) {
			this.npiNumbers = this.npiNumbers.filter((ele) => ele !== npi);
			this.npiNumberInput.nativeElement.value = '';
			this.npiNumberControl.setValue(null);
		} else {
			this.npiNumberInput.nativeElement.value = '';
			this.npiNumberControl.setValue(null);
			this.npiNumbers.push(npi);
		}
		this.omitChange();
		this.storeFilterData();
	}
	onExperienceSelect(event: MatAutocompleteSelectedEvent) {
		if (this.experience.findIndex((hospital) => hospital === event.option.viewValue) === -1) {
			this.experience.push(event.option.viewValue);
			this.experienceInput.nativeElement.value = '';
			this.experienceControl.setValue(null);
			const temp: any = [];
			this.experienceList = temp;
			this.omitChange();
			this.storeFilterData();
		}
	}
	onCodeSelect(event: MatAutocompleteSelectedEvent) {
		this.selectedHSCPCCode = [...new Set([...this.selectedHSCPCCode, event.option.value])];
		this.cptCodeInput.nativeElement.value = '';
		setTimeout(() => {
			this.getHSCPCCodes();
		}, 500);
		this.omitChange();
		this.storeFilterData();
	}

	removeNPI(content): void {
		this.npiNumbers = this.npiNumbers.filter((ele) => ele !== content);
		this.npiNumberList = [];
		this.omitChange();
		this.storeFilterData();
	}
	removeExperience(content): void {
		this.experience = this.experience.filter((ele) => ele !== content);
		this.experienceList = [];
		this.omitChange();
		this.storeFilterData();
	}
	addHospitalName(event: MatChipInputEvent): void {
		const value = (event.value || '').trim();
		const found = this.hospitalNames.indexOf(value);
		// Add our fruit
		if (value && found === -1) {
			this.hospitalNames.push(value);
		}
		// Clear the input value
		event.input.value = '';
		// event.input.value = "";
		// event.chipInput!.clear();

		this.hospitalControl.setValue(null);
		this.omitChange();
		this.storeFilterData();
	}

	storeFilterData() {
		this.filterStorageService.set('physician_hospital', this.hospitalNames);
		// this.filterStorageService.set('physician_experience', this.experience);
		this.filterStorageService.set('physician_specialityIncluded', this.includedSpecialities);

		this.filterStorageService.set('physician_specialityExcluded', this.excludedSpecialities);
		this.filterStorageService.set('physician_physicianName', this.physicianName);
		this.filterStorageService.set('physician_cityList', this.filterData.cityList);
		this.filterStorageService.set('physician_stateList', this.filterData.stateList);
		this.filterStorageService.set('physician_npiNumber', this.npiNumbers);
		this.filterStorageService.set('selected_code_list', this.filteredCodes);
		this.filterStorageService.set('emailTypeIsp', this.emailTypeIsp);

		this.filterStorageService.set('physician_classification', this.filterData.classification);
		this.filterStorageService.set('physician_firmType', this.filterData.firmtype);
		this.filterStorageService.set('physician_ehrProductName', this.tempDialogFilters.ehrproductname);
		this.filterStorageService.set(
			'physician_ehrProductClassification',
			this.tempDialogFilters.productclassification
		);
		this.filterStorageService.set(
			'physician_totalClaimsRange',
			this.tempDialogFilters.totalclaimsrange
		);
		this.filterStorageService.set('physician_zipCode', this.filterData.zipcode);

		// Advance Filters
		// if (this.dialogState == true) {
		// }
		this.action_classificationSpeciality('store');
		this.action_emailStatus('store');
		this.action_advanceFilter('store');
		this.action_ageRangeList('store');
		this.action_procedureCodes('store');
		this.action_language('store');
		this.action_expirence('store');
		this.action_teleHealth('store');
		this.action_teleHealth('store');
		this.action_zipCodeByRadius('store');
		this.action_education('store');
		this.actionEducationLocation('store');
		this.filterStorageService.set('physician_stateLicences', this.tempDialogFilters.licenses_state);
		// this.filterStorageService.set('physician_languages', this.tempDialogFilters.languages);
	}
	get getAge() {
		return this.age;
	}

	omitChange() {
		this.onFilterChange.emit({
			hospitalNameList: this.hospitalNames,
			specialityIncluded: this.includedSpecialities,
			specialityExcluded: this.excludedSpecialities,
			physicianName: this.physicianName,
			cityList: this.filterData.cityList,
			stateList: this.filterData.stateList,
			npiNumber: this.npiNumbers,
			leadWithEmail: this.leadWithEmail,
			age: this.age,
			experience: this.tempDialogFilters.experience,
			cptCodes: this.tempDialogFilters.cptCodes.map(String),
			hcpcsCodes: this.tempDialogFilters.hcpcsCodes.map(String),
			specialityGroup: this.tempDialogFilters.specialityGroup,
			speciality: this.tempDialogFilters.speciality,
			subSpeciality: this.tempDialogFilters.subSpeciality,
			// speciality: [...this.tempDialogFilters.speciality.map((item) => item.speciality)],
			// subSpeciality: [...this.tempDialogFilters.subSpeciality.map((item) => item)],
			ageRangeList: this.tempDialogFilters.ageRangeList,
			languages: this.tempDialogFilters.languages,
			emailTypeIsp: this.tempDialogFilters.emailTypeIsp,
			email_Score: this.tempDialogFilters.email_Score,
			provider_Type: this.tempDialogFilters.provider_Type,
			bsn: this.tempDialogFilters.bsn,
			abmsabps: this.tempDialogFilters.abmsabps,
			abom: this.tempDialogFilters.abom,
			istelehealth: this.tempDialogFilters.istelehealth,
			firmtype: this.filterData.firmtype,
			classification: this.filterData.classification,
			ehrproductname: this.tempDialogFilters.ehrproductname,
			productclassification: this.tempDialogFilters.productclassification,
			totalclaimsrange: this.tempDialogFilters.totalclaimsrange,
			zipcode: this.filterData.zipcode,
			radiusZipcodes: this.tempDialogFilters.radiusZipcodes,
			residencyhospitals: this.tempDialogFilters.residencyhospitals,
			medicalschools: this.tempDialogFilters.medicalschools,
			fellowships: this.tempDialogFilters.fellowships,
			licenses_state: this.tempDialogFilters.licenses_state,
			undergraduate_state: this.tempDialogFilters.undergraduate_state,
			medicalschool_state: this.tempDialogFilters.medicalschool_state,
			residency_state: this.tempDialogFilters.residency_state,
			fellowship_state: this.tempDialogFilters.fellowship_state,
			keyword: this.tempDialogFilters.keyword
		});
		this.changeSearchData();
		this.storeFilterData();
	}

	changeSearchData() {
		this.healthCareDataService.changePhysicainSearchData({
			hospitalNameList: this.hospitalNames,
			specialityIncluded: this.includedSpecialities,
			specialityExcluded: this.excludedSpecialities,
			physicianName: this.physicianName,
			cityList: this.selectedCities,
			stateList: this.selectedStates,
			npiNumber: this.npiNumbers,
			leadWithEmail: this.leadWithEmail,
			provider_Type: this.leadWithProvider,
			leadWithPhone: false,
			email_Score: this.emailStatus,
			cptCodes: this.selectedCPTCode,
			hcpcsCodes: this.selectedHSCPCCode,
			emailTypeIsp: this.emailTypeIsp,
			experience: this.experience,
			languager: this.languages
		});
	}
	removeHospital(val: any): void {
		this.hospitalNames = this.hospitalNames.filter((name) => name !== val);
		const temp: any = [];
		this.filteredHospitals = temp;
		this.omitChange();
		this.storeFilterData();
	}
	addIncludeSpeciality(event: MatChipInputEvent): void {
		const value = (event.value || '').trim();
		const found = this.includedSpecialities.indexOf(value);
		// Add our fruit
		if (value && found === -1) {
			this.includedSpecialities.push(value);
			// Clear the input value
			event.input.value = '';
		}
		this.omitChange();
		this.storeFilterData();
		this.getSpecialitySimilarTerms(event.value);
	}
	addExcludeSpeciality(event: MatChipInputEvent): void {
		const value = (event.value || '').trim();
		const found = this.excludedSpecialities.indexOf(value);
		// Add our fruit
		if (value && found === -1) {
			this.excludedSpecialities.push(value);
			// Clear the input value
			event.input.value = '';
		}
		this.omitChange();
		this.storeFilterData();
		this.getSpecialitySimilarTerms(event.value, false);
	}
	selectedIncludeSpeciality(event: MatAutocompleteSelectedEvent): void {
		this.includedSpecialities.push(event.option.viewValue);
		this.includeSpecialityInput.nativeElement.value = '';
		this.includeSpecialityControl.setValue(null);
		this.suggestionTerms = this.suggestionTerms.filter((st) => st !== event.option.viewValue);
		this.suggestionTermsEx = this.suggestionTermsEx.filter((st) => st !== event.option.viewValue);
		this.omitChange();
		this.storeFilterData();
		this.getSpecialitySimilarTerms(event.option.viewValue);
	}
	selectedExcludeSpeciality(event: MatAutocompleteSelectedEvent): void {
		this.excludedSpecialities.push(event.option.viewValue);
		this.excludeSpecialityInput.nativeElement.value = '';
		this.excludeSpecialityControl.setValue(null);
		this.suggestionTerms = this.suggestionTerms.filter((st) => st !== event.option.viewValue);
		this.suggestionTermsEx = this.suggestionTermsEx.filter((st) => st !== event.option.viewValue);
		this.omitChange();
		this.storeFilterData();
		this.getSpecialitySimilarTerms(event.option.viewValue, false);
	}
	termsSelected(val: any) {
		this.suggestionTerms = this.suggestionTerms.filter((st) => st !== val);
		this.suggestionTermsEx = this.suggestionTermsEx.filter((st) => st !== val);
		this.includedSpecialities.push(val);
		this.includeSpecialityInput.nativeElement.value = '';
		this.includeSpecialityControl.setValue(null);
		this.getSpecialitySimilarTerms(val, true);
		this.omitChange();
		this.storeFilterData();
	}
	termsSelectedEx(val: any) {
		this.suggestionTerms = this.suggestionTerms.filter((st) => st !== val);
		this.suggestionTermsEx = this.suggestionTermsEx.filter((st) => st !== val);
		this.excludedSpecialities.push(val);
		this.excludeSpecialityInput.nativeElement.value = '';
		this.excludeSpecialityControl.setValue(null);
		// this.getSpecialitySimilarTerms(val, false);
		this.omitChange();
		this.storeFilterData();
	}
	getSpecialitySimilarTerms(val: any, isIncluded: boolean = true) {
		var preSpeciality = this.includedSpecialities.concat(this.excludedSpecialities);
		this.amplizService
			.getSpecialitySuggestion({
				searchPhrase: val,
				chosenSpecialityList: preSpeciality
			})
			.subscribe((response) => {
				if (response) {
					var tempTerms = [];
					if (response.specialityRelatedTermList.length > 0) {
						tempTerms = response.specialityRelatedTermList.map((s) => {
							var sList = s.specialitySuggestion;
							// for (let i = 0; i < sGroup.length && sList.length < 10; i++) {
							//   const sListEle = sGroup[i].specialityList;
							//   for (let l = 0; l < sListEle.length && sList.length < 10; l++) {
							//     const element = sListEle[l];
							//     sList.push(element);
							//   }
							// }
							return sList;
						});
					}
					if (isIncluded) {
						this.suggestionTerms = tempTerms;
					} else {
						this.suggestionTermsEx = tempTerms;
					}
				} else {
					if (isIncluded) {
						8;
						this.suggestionTerms = [];
					} else {
						this.suggestionTermsEx = [];
					}
				}
			});
	}
	removeIncludeSpeciality(val: any): void {
		this.includedSpecialities = this.includedSpecialities.filter((spec) => spec !== val);
		if (this.includedSpecialities.length > 0) {
			var lastE = this.includedSpecialities[this.includedSpecialities.length - 1];
			this.getSpecialitySimilarTerms(lastE, true);
		}
		this.omitChange();
		this.storeFilterData();
		if (this.includedSpecialities.length === 0) {
			this.filteredSpeciality = [];
			this.suggestionTerms = [];
		}
	}
	removeExcludeSpeciality(val: any): void {
		this.excludedSpecialities = this.excludedSpecialities.filter((spec) => spec !== val);
		if (this.includedSpecialities.length > 0) {
			var lastE = this.includedSpecialities[this.includedSpecialities.length - 1];
			this.getSpecialitySimilarTerms(lastE, true);
		}
		this.omitChange();
		this.storeFilterData();
		if (this.excludedSpecialities.length === 0) {
			this.filteredSpecialityEx = [];
			this.suggestionTermsEx = [];
		}
	}
	addPhysicianName(event: any): void {
		this.physicianName = event.target.value;
		if (event.target.value.length >= 3 || event.target.value.length == 0) {
			this.omitChange();
			this.storeFilterData();
		}
	}
	// citySelected(event: MatSelectChange): void {
	//
	//   this.selectedCities.push(event.value[0]);
	//   this.omitChange();
	//   this.storeFilterData();
	// }
	// addEmailStatus(event): void {
	// 	if (typeof event.value === 'string') {
	// 		event.value = parseInt(event.value);
	// 	}
	// 	if (event.value !== 0) {
	// 		this.emailStatus = event.value;
	// 		// this.emailStatusInput.nativeElement.value = '';
	// 	}
	// 	this.omitChange();
	// 	this.storeFilterData();
	// }
	addCodeClassification(event): void {
		this.selectedCPTCode = [...new Set([...this.selectedCPTCode, event.option.value])];

		this.codeClassificationInput.nativeElement.value = '';
		setTimeout(() => {
			this.getCPTCodes();
		}, 500);

		this.omitChange();
		this.storeFilterData();
	}
	bsnChange() {
		this.tempDialogFilters.bsn = this.tempDialogFilters.bsn !== 'Yes' ? 'Yes' : 'No';
		this.getRecordCount();
	}
	removeCode(code: string) {
		this.selectedHSCPCCode = this.selectedHSCPCCode.filter((el) => el !== code);
		this.getHSCPCCodes();
		this.omitChange();
		this.storeFilterData();
	}
	removeCPTCode(code: string) {
		this.selectedCPTCode = this.selectedCPTCode.filter((el) => el !== code);
		this.getCPTCodes();
		this.omitChange();
		this.storeFilterData();
	}

	removeEmailStatus() {
		this.emailStatus = 0;

		this.omitChange();
		this.storeFilterData();
	}

	selectedHospitalName(event) {
		if (this.hospitalNames.findIndex((hospital) => hospital === event) !== -1) return;

		this.hospitalNames = event;
		this.omitChange();
		this.storeFilterData();
	}

	// selectedHospitalName(event: MatAutocompleteSelectedEvent): void {
	// 	if (this.hospitalNames.findIndex((hospital) => hospital === event.option.viewValue) === -1) {
	// 		this.hospitalNames.push(event.option.value);
	// 		this.hospitalNameInput.nativeElement.value = '';
	// 		this.hospitalControl.setValue(null);
	// 		const temp: any = [];
	// 		this.filteredHospitals = temp;
	// 		this.omitChange();
	// 		this.storeFilterData();
	// 	}
	// }
	// selectStates(event: MatAutocompleteSelectedEvent): void {
	// 	const receivedState = event.option.value;
	// 	const found = this.selectedStates.findIndex((ele) => ele.stateId === receivedState.stateId);
	// 	if (found === -1) {
	// 		this.selectedStates.push(receivedState);
	// 		this.omitChange();
	// 		this.storeFilterData();
	// 		// setTimeout(() => {
	// 		//   this.addCitiesInList(receivedState);
	// 		// }, 200);
	// 	}
	// }
	// selectCity(event: MatAutocompleteSelectedEvent): void {
	//   this.selectedCities.push(event.option.value);
	//   this.cityInput.nativeElement.value = "";
	//   this.cityControl.setValue(null);
	//   this.omitChange();
	//   this.storeFilterData();
	//
	// }
	addCitiesInList(state: any) {
		this.amplizService.getCityListForState(state.stateId).subscribe((response) => {
			this.filteredCities = [...this.filteredCities, ...response.cityDataList];
			this.searchCity = this.filteredCities;
			// this.filteredCities.push(response.cityDataList);
		});
	}
	removeState(inState: any) {
		this.selectedStates = this.selectedStates.filter((state) => inState.stateId !== state.stateId);
		this.omitChange();
		this.storeFilterData();
	}

	clearAll() {
		this.includeSpecialityControl.setValue(null);
		this.includeSpecialityInput.nativeElement.value = '';
		this.excludeSpecialityControl.setValue(null);
		this.excludeSpecialityInput.nativeElement.value = '';
		this.emailStatusControl.setValue(null);
		this.codeClassificationControl.setValue(null);
		this.ageControl.setValue('');
		this.npiNumberInput.nativeElement.value = '';
		this.hospitalNames = [];
		this.includedSpecialities = [];
		this.excludedSpecialities = [];
		this.selectedCPTCode = [];
		this.selectedHSCPCCode = [];
		this.filterData = new SearchPhysicianModel();
		this.filteredCodes = [];
		this.physicianName = '';
		this.selectedCities = [];
		this.selectedStates = [];
		this.npiNumbers = [];
		this.experience = [];
		this.suggestionTerms = [];
		this.suggestionTermsEx = [];
		this.searchCity = [];
		this.stateList = [];
		this.filteredCities = [];
		this.filteredSpeciality = [];
		this.filteredSpecialityEx = [];
		this.cityControl.setValue(null);
		this.npiNumberList = [];
		this.experienceList = [];
		// this.leadWithProvider = false;
		// this.emailTypeIsp = false;
		this.leadWithEmail = false;
		this.age = '';

		this.action_classificationSpeciality('clear');
		this.action_emailStatus('clear');
		this.action_ageRangeList('clear');
		this.action_expirence('clear');
		this.action_language('clear');
		this.action_advanceFilter('clear');
		this.action_procedureCodes('clear');
		this.action_teleHealth('clear');
		this.getCPTCodes();
		this.getHSCPCCodes();
		this.action_zipCodeByRadius('clear');
		this.clearFilter('clearAll');
		this.tempDialogFilters = new SearchPhysicianModel();
		this.omitChange();
		this.storeFilterData();
		this.filterCleared.emit();
	}

	displayFn(selectedState: any): string {
		return selectedState.stateId;
	}

	getHospitalName(name: any) {
		if (name.length <= 3 || name === '') return;
		this.amplizService.getHospitalList({ searchPhase: name }).subscribe((response) => {
			this.filteredHospitals = response.hospitalDataList;
		});
	}

	getAllListData() {
		// change control for hospital name
		this.hospitalControl.valueChanges.subscribe((value) => {
			let hv = value !== null ? value : '';
			if (hv.length >= 3) {
				this.amplizService.getHospitalList({ searchPhase: value }).subscribe((response) => {
					this.filteredHospitals = response.hospitalDataList;
					// this.stateList = response.hospitalDataList;
				});
			} else {
				const tempHospital: any = [];
				this.filteredHospitals = tempHospital;
			}
		});
		// change control for states
		this.stateControl.valueChanges.subscribe((value) => {
			let hv = value !== null ? value : '';
			if (hv.length >= 2) {
				this.amplizService.getStateList({ searchPhase: value }).subscribe((response) => {
					this.filteredStates = response.stateDataList;
				});
			} else {
				const temp: any = [];
				this.filteredStates = temp;
			}
		});

		// Languages Control
		this.languagesControl.valueChanges.pipe(debounceTime(300)).subscribe((value) => {
			// this.getLanguageList(value);
			this.action_language('api', value);
		});

		// change control of NPI
		this.npiNumberControl.valueChanges.subscribe((value) => {
			let hv = value !== null ? value : '';
			if (hv.length >= 1) {
				this.amplizService.getNPINumberList({ searchPhase: value }).subscribe((response) => {
					this.npiNumberList = response.npiNumberList;
				});
			} else {
				const temp: any = [];
				this.npiNumberList = temp;
			}
		});
		this.experienceControl.valueChanges.subscribe((value) => {
			let hv = value !== null ? value : '';
			if (hv.length >= 3) {
				this.amplizService.getExperienceList({ searchPhase: value }).subscribe((response) => {
					this.experienceList = response.experienceList;
				});
			} else {
				const temp: any = [];
				this.experienceList = temp;
			}
		});
		this.cptControl.valueChanges.subscribe((value) => {
			let hv = value !== null ? value : '';
			if (hv) {
				this.getHSCPCCodes(hv);
			}
		});
		this.codeClassificationControl.valueChanges.subscribe((value) => {
			let hv = value !== null ? value : '';
			if (hv) {
				this.getCPTCodes(hv);
			}
		});
		// change control of Include Speciality
		this.includeSpecialityControl.valueChanges.subscribe((value) => {
			let hv = value !== null ? value : '';
			if (hv.length >= 3) {
				var preSpeciality = this.includedSpecialities.concat(this.excludedSpecialities);
				this.amplizService
					.getSpecialityList({
						searchPhrase: value,
						previousSearchSpeciality: preSpeciality
					})
					.subscribe((response) => {
						this.filteredSpeciality = response.specialityAll;
					});
			} else {
				const temp: any = [];
				this.filteredSpeciality = temp;
			}
		});
		// change control of Speciality
		this.excludeSpecialityControl.valueChanges.subscribe((value) => {
			let hv = value !== null ? value : '';
			if (hv.length >= 3) {
				var preSpeciality = this.includedSpecialities.concat(this.excludedSpecialities);
				this.amplizService
					.getSpecialityList({
						searchPhrase: value,
						previousSearchSpeciality: preSpeciality
					})
					.subscribe((response) => {
						this.filteredSpecialityEx = response.specialityAll;
					});
			} else {
				const temp: any = [];
				this.filteredSpecialityEx = temp;
			}
		});
		// city input change
		this.cityControl.valueChanges.subscribe((value) => {
			let hv = value !== null ? value : '';
			if (hv.length >= 3) {
				// event.cityList.map((city) => city.city);
				var tStates: any = [];
				if (this.dialogState) {
					tStates = this.tempDialogFilters.stateList.map((state: any) => state.stateId);
				} else {
					tStates = this.selectedStates.map((state) => state.stateId);
				}
				var params = { stateId: tStates, searchPhase: value };
				this.amplizService.searchCitiesForStates(params).subscribe((response) => {
					this.filteredCities = [...response.cityDataList];
					this.searchCity = this.filteredCities;
					// this.filteredCities.push(response.cityDataList);
				});
			} else {
				const temp: any = [];
				this.searchCity = temp;
			}
		});

		this.zipControl.valueChanges.subscribe((value) => {
			let hv = value !== null ? value : '';
			if (hv.length >= 3) {
				this.amplizService.getZipCodeList(value).subscribe((response) => {
					this.zipCodeList = response.zipcodeList;
				});
			} else {
				const temp: any = [];
				this.zipCodeList = temp;
			}
		});

		// Classification Speciality controls
	}
	searchCityListForStates(searchPhrase: any) {
		if (searchPhrase.trim().length > 0) {
			//
			var tStates = this.stateList;
			var params = { stateId: tStates, searchPhase: searchPhrase };
			this.amplizService.searchCitiesForStates(params).subscribe((response) => {
				this.filteredCities = [...this.filteredCities, ...response.cityDataList];
				this.searchCity = this.filteredCities;
				// this.filteredCities.push(response.cityDataList);
			});
		}
	}

	handleChange(value, model) {
		if (model === 'emailTypeIsp') {
			this.emailTypeIsp = value;
		} else if (model === 'leadWithPhone') {
		} else if (model === 'Independent') {
			this.provider_Type = value;
		}
		this.omitChange();
		this.storeFilterData();
	}

	async requestPricing() {
		const emailId = await localStorage.getItem('email_id');
		this.loaderService.display(true);
		const body = { package: 'Enterprise', email: emailId };
		this.amplizService.getPrice(body).subscribe(
			(res) => {
				this.loaderService.display(false);

				this.messageService.display(true, 'Thanks for asking, will get back to you in 24 hrs');
			},
			(error) => {
				this.loaderService.display(false);
				this.messageService.displayError(true, error.error.msg ? error.error.msg : 'Server Error !!!');
			}
		);
	}
	triggerAutoFocus(eleId: string) {
		const element = this.renderer.selectRootElement(eleId);
		setTimeout(() => element.focus(), 100);
	}

	getCPTCodes(searchPhrase: string = '') {
		this.amplizService.getCptCodes(searchPhrase).subscribe((res: any) => {
			this.cptCodeList = res.cptCodes;
		});
	}
	getHSCPCCodes(searchPhrase: string = '') {
		this.amplizService.getHcpcsCodes(searchPhrase).subscribe((res: any) => {
			this.hscpcCodeList = res.hcpcsCodes;
		});
	}
	getKeywordList(searchPhrase: string) {
		this.amplizService.getKeywordList(searchPhrase).subscribe((res) => {
			this.keywordList = res.keyword_list;
		});
	}

	// More Filters functions

	get countLoader() {
		return this.recordCountLoader.value;
	}

	async openMoreFilter() {
		this.dialogRef = this.dialog.open(this.filterDialog, {
			id: 'more-option-dialog',
			height: '600px',
			disableClose: true
		});
		// this.tempDialogFilters = new SearchPhysicianModel();
		this.dialogState = true;
		await this.getMainFilters();
		this.getRecordCount();
		this.dialogRef.afterClosed().pipe(
			finalize(() => {
				this.dialogRef = undefined;
			})
		);
	}

	async getMainFilters() {
		this.tempDialogFilters.specialityIncluded = [...this.includedSpecialities];
		this.tempDialogFilters.specialityExcluded = [...this.excludedSpecialities];

		this.tempDialogFilters.experience = [...this.experience];

		this.tempDialogFilters.cptCodes = [...this.selectedCPTCode];
		this.tempDialogFilters.hcpcsCodes = [...this.selectedHSCPCCode];

		this.tempDialogFilters.email_Score = this.emailStatus;

		this.tempDialogFilters.hospitalNameList = [...this.hospitalNames];

		this.tempDialogFilters.npiNumber = [...this.npiNumbers];

		this.tempDialogFilters.cityList = [...this.filterData.cityList];
		this.tempDialogFilters.stateList = [...this.filterData.stateList];
		this.tempDialogFilters.zipcode = [...this.filterData.zipcode];

		this.tempDialogFilters.physicianName = this.physicianName;
		this.tempDialogFilters.leadWithEmail = this.leadWithEmail;

		this.tempDialogFilters.firmtype = this.filterData.firmtype;
		this.tempDialogFilters.classification = this.filterData.classification;

		this.tempDialogFilters.zipcode = this.filterData.zipcode;

		this.tempDialogFilters.age = this.age;
		this.tempDialogFilters.searchType = 'TOTAL';

		// Advance Filters

		// ProcedureCodes
		await this.action_procedureCodes('fetch');
		// Email Status
		await this.action_emailStatus('fetch');
		// Language
		await this.action_language('fetch');
		// Classification Speciality
		await this.action_classificationSpeciality('fetch');
		// Age Range
		await this.action_ageRangeList('fetch');
		// experience filter
		await this.action_expirence('fetch');
		// Advance Filter
		await this.action_advanceFilter('fetch');
		// tele health
		await this.action_teleHealth('fetch');

		// clone the object to check for the changes in future
		this.cloneTempDialogFilters = cloneDeep(this.tempDialogFilters);
	}

	selectDialogFilter(type: any, value: any, subType?: any) {
		switch (type) {
			case 'speciality':
				if (subType == 'included') {
					this.tempDialogFilters.specialityIncluded.indexOf(value.option.value) == -1
						? this.tempDialogFilters.specialityIncluded.push(value.option.value)
						: '';
				} else {
					this.tempDialogFilters.specialityExcluded.indexOf(value.option.value) == -1
						? this.tempDialogFilters.specialityExcluded.push(value.option.value)
						: '';
				}
				break;

			case 'experience':
				this.tempDialogFilters.experience.indexOf(value.option.value) == -1
					? this.tempDialogFilters.experience.push(value.option.value)
					: '';
				break;

			case 'procedureCode':
				if (subType == 'cpt') {
					this.tempDialogFilters.cptCodes.indexOf(value.option.value) == -1
						? this.tempDialogFilters.cptCodes.push(value.option.value)
						: '';
				} else {
					this.tempDialogFilters.hcpcsCodes.indexOf(value.option.value) == -1
						? this.tempDialogFilters.hcpcsCodes.push(value.option.value)
						: '';
				}
				break;

			case 'emailStatus':
				value.value !== undefined
					? (this.tempDialogFilters.email_Score = parseInt(value.value))
					: (this.tempDialogFilters.email_Score = value.option.value);
				break;

			case 'hospitalName':
				this.tempDialogFilters.hospitalNameList.indexOf(value) == -1
					? (this.tempDialogFilters.hospitalNameList = value)
					: '';

				break;

			case 'npi':
				this.tempDialogFilters.npiNumber.indexOf(value) == -1
					? this.tempDialogFilters.npiNumber.push(value)
					: '';
				break;

			case 'location':
				if (subType == 'state') {
					const stateId = this.tempDialogFilters.stateList.map((item: any) => item.stateId);
					stateId.indexOf(value.option.value.stateId) == -1
						? this.tempDialogFilters.stateList.push(value.option.value)
						: '';
				} else {
					const cityId = this.tempDialogFilters.cityList.map((item: any) => item.cityId);
					cityId.indexOf(value.cityId) == -1 ? this.tempDialogFilters.cityList.push(value) : '';
				}
				break;

			case 'advance':
				if (subType === 'privatePractioner') {
					this.tempDialogFilters.provider_Type = value ? 'Independent' : '';
				} else {
					this.tempDialogFilters.emailTypeIsp = value ? 'Yes' : '';
				}
				this.toggleFilterLength();
				break;

			case 'language':
				this.tempDialogFilters.languages.indexOf(value.option.value) === -1
					? this.tempDialogFilters.languages.push(value.option.value)
					: '';
				this.languageList = [];
				break;

			case 'classificationSpeciality':
				if (subType === 'specGroup') {
					if (this.tempDialogFilters.specialityGroup != value.specialityType) {
						this.tempDialogFilters.specialityGroup = value.specialityType;
						this.tempDialogFilters.speciality = [];
						this.tempDialogFilters.subSpeciality = [];
						this.getSpecialityOfSpecialityGroup(value.id);
					}
				}
				if (subType === 'speciality') {
					if (this.tempDialogFilters.speciality.filter((e) => e.id == value.id).length <= 0) {
						this.tempDialogFilters.speciality.push(value);
						const specialityId = this.tempDialogFilters.speciality.map((item) => item.id);
						this.getSubSpecialityForSpeciality([...specialityId]);
					}
				}
				if (subType === 'subSpeciality') {
					if (this.tempDialogFilters.subSpeciality.indexOf(value.subspeciality) == -1) {
						this.tempDialogFilters.subSpeciality.push(value.subspeciality);
					}
				}
				break;
			case 'zip':
				this.tempDialogFilters.zipcode.indexOf(value) == -1
					? this.tempDialogFilters.zipcode.push(value)
					: '';
				break;

			// case 'age':
			// 	break;
		}
		this.getRecordCount();
	}
	// get bsnValue() {
	// 	return this.tempDialogFilters.bsn === 'Yes' ? true : false;
	// }

	removeDialogFilter(type: any, value: any, subType?: any) {
		switch (type) {
			case 'speciality':
				subType === 'included'
					? (this.tempDialogFilters.specialityIncluded =
							this.tempDialogFilters.specialityIncluded.filter((ele) => ele !== value))
					: (this.tempDialogFilters.specialityExcluded =
							this.tempDialogFilters.specialityExcluded.filter((ele) => ele !== value));
				break;

			case 'experience':
				this.tempDialogFilters.experience = this.tempDialogFilters.experience.filter(
					(ele) => ele !== value
				);
				break;

			case 'procedureCode':
				subType === 'cpt'
					? (this.tempDialogFilters.cptCodes = this.tempDialogFilters.cptCodes.filter(
							(ele) => ele !== value
					  ))
					: (this.tempDialogFilters.hcpcsCodes = this.tempDialogFilters.hcpcsCodes.filter(
							(ele) => ele !== value
					  ));
				break;

			case 'emailStatus':
				this.tempDialogFilters.email_Score = 0;
				break;

			case 'hospitalName':
				this.tempDialogFilters.hospitalNameList = this.tempDialogFilters.hospitalNameList.filter(
					(ele) => ele !== value
				);
				break;

			case 'npi':
				this.tempDialogFilters.npiNumber = this.tempDialogFilters.npiNumber.filter(
					(ele) => ele !== value
				);
				break;

			case 'location':
				subType === 'state'
					? (this.tempDialogFilters.stateList = this.tempDialogFilters.stateList.filter(
							(ele) => ele !== value
					  ))
					: (this.tempDialogFilters.cityList = this.tempDialogFilters.cityList.filter(
							(ele) => ele !== value
					  ));
				break;

			case 'language':
				this.tempDialogFilters.languages = this.tempDialogFilters.languages.filter(
					(item) => item !== value
				);
				break;

			case 'classificationSpeciality':
				if (subType === 'specGroup') {
					this.tempDialogFilters.specialityGroup = '';
					this.tempDialogFilters.speciality = [];
					this.tempDialogFilters.subSpeciality = [];
					this.c_specialityList = [];
					this.c_subSpecialityList = [];
				}
				if (subType === 'speciality') {
					this.tempDialogFilters.speciality = this.tempDialogFilters.speciality.filter(
						(ele) => ele !== value
					);
					this.tempDialogFilters.subSpeciality = [];
					this.c_subSpecialityList = [];
					this.getSubSpecialityForSpeciality(this.tempDialogFilters.speciality.map((item) => item.id));
				}
				if (subType === 'subSpeciality') {
					this.tempDialogFilters.subSpeciality = this.tempDialogFilters.subSpeciality.filter(
						(ele) => ele !== value
					);
				}
				break;

			case 'zip':
				this.tempDialogFilters.zipcode = this.tempDialogFilters.zipcode.filter((ele) => ele !== value);
				break;

			case 'age':
				this.tempDialogFilters.ageRangeList = this.tempDialogFilters.ageRangeList.filter(
					(ele) => ele !== value
				);
				break;
		}

		this.getRecordCount();
	}

	clearFilter(type: any, callGetRecord = true) {
		switch (type) {
			case 'clearAll':
				// this.tempDialogFilters = new SearchPhysicianModel();
				// this.tempDialogFilters.searchType = 'TOTAL';
				this.d_personalEmail = false;
				this.d_privatePractioner = false;
				this.c_specialityList = [];
				this.c_subSpecialityList = [];
				if (this.dialogState == true) {
					this.tempDialogFilters = new SearchPhysicianModel();
					this.selectedZipCodeForRadius = '';
				}
				break;
			case 'speciality':
				this.tempDialogFilters.specialityIncluded = [];
				this.tempDialogFilters.specialityExcluded = [];
				break;
			case 'experience':
				this.tempDialogFilters.experience = [];
				break;
			case 'procedureCode':
				this.tempDialogFilters.cptCodes = [];
				this.tempDialogFilters.hcpcsCodes = [];
				break;
			case 'emailStatus':
				this.tempDialogFilters.email_Score = 0;
				break;
			case 'hospitalName':
				this.tempDialogFilters.hospitalNameList = [];
				break;
			case 'npi':
				this.tempDialogFilters.npiNumber = [];
				break;
			case 'location':
				this.tempDialogFilters.stateList = [];
				this.tempDialogFilters.cityList = [];
				this.tempDialogFilters.zipcode = [];
				break;
			case 'language':
				this.tempDialogFilters.languages = [];
				break;
			case 'classificationSpeciality':
				this.tempDialogFilters.specialityGroup = '';
				this.tempDialogFilters.speciality = [];
				this.tempDialogFilters.subSpeciality = [];
				this.c_specialityList = [];
				this.c_subSpecialityList = [];
				break;
			case 'advance':
				this.tempDialogFilters.emailTypeIsp = '';
				this.tempDialogFilters.provider_Type = '';
				this.d_personalEmail = false;
				this.d_privatePractioner = false;
				break;
			case 'age':
				this.tempDialogFilters.ageRangeList = [];
				break;
		}

		callGetRecord && this.getRecordCount();
	}

	getRecordCount() {
		// this.moreFilterRecordCount = null;
		if (this.dialogRef !== undefined) {
			this.tempDialogFilters.searchType = 'TOTAL';
			this.recordCountLoader.next(true);
			this.amplizService.getTotalCount(this.tempDialogFilters.toJson()).subscribe(async (res) => {
				this.moreFilterRecordCount.next(res.totalResult);
				await setTimeout(() => {
					this.recordCountLoader.next(false);
				}, 100);
			});
		}
	}

	filterApiValues() {
		let _tempObj: any = { ...this.tempDialogFilters.toJson() };

		if (_tempObj.stateList.length > 0) {
			_tempObj.stateList = _tempObj.stateList.map((item: any) => item.state);
			if (_tempObj.cityList.length > 0) {
				_tempObj.cityList = _tempObj.cityList.map((item: any) => item.city);
			}
		}
		if (_tempObj.speciality.length > 0) {
			_tempObj.speciality = _tempObj.speciality.map((item) => item.speciality);
			if (_tempObj.subSpeciality.length > 0) {
				_tempObj.subSpeciality = _tempObj?.subSpeciality?.map((item) => item) || [];
			}
		}
		return _tempObj;
	}

	get isMoreFilterChanged() {
		return !isEqual(this.tempDialogFilters, this.cloneTempDialogFilters);
	}

	closeDialogBox(type: any, clearAll?: boolean) {
		if (type === 'confirmDialog') {
			this.showConfirmDialog = false;
		}
		if (type === 'mainDialog') {
			this.isMoreFilterChanged ? (this.showConfirmDialog = true) : this.dialogRef.close();
			this.dialogState = false;
		}
		if (type === 'mainDialog' && clearAll == true) {
			this.dialogState = false;
			this.clearFilter('clearAll', false);
			this.showConfirmDialog = false;
			this.dialogRef.close();
		}
	}

	toggleFilterLength() {
		let count = 0;
		if (this.d_personalEmail) {
			count++;
		}
		if (this.d_privatePractioner) {
			count++;
		}
		this.d_advanceFilterLength = count;
	}

	async applyMoreFilters() {
		this.hospitalNames = this.tempDialogFilters.hospitalNameList;
		this.includedSpecialities = this.tempDialogFilters.specialityIncluded;
		this.excludedSpecialities = this.tempDialogFilters.specialityExcluded;
		this.physicianName = this.tempDialogFilters.physicianName;
		this.filterData.cityList = this.tempDialogFilters.cityList;
		this.filterData.stateList = this.tempDialogFilters.stateList;
		this.filterData.zipcode = this.tempDialogFilters.zipcode;
		this.npiNumbers = this.tempDialogFilters.npiNumber;

		this.filterData.firmtype = this.tempDialogFilters.firmtype;
		this.filterData.classification = this.tempDialogFilters.classification;

		this.tempDialogFilters.provider_Type = this.d_privatePractioner == true ? 'Independent' : '';
		this.tempDialogFilters.emailTypeIsp = this.d_personalEmail == true ? 'Yes' : '';

		this.tempDialogFilters.istelehealth = this.d_teleHealth ? 'Y' : '';

		await this.omitChange();
		await this.storeFilterData();

		this.gridLoader.display(true);
		setTimeout(() => {
			this.closeDialogBox('mainDialog', true);
		}, 200);
	}

	getSpecialityOfSpecialityGroup(specialityGroup: any) {
		const params = {
			specialityGroupId: specialityGroup
		};
		this.amplizService.getSpecialityForSpecialityGroup(params).subscribe((res: any) => {
			this.c_specialityList = res.specialityList;
		});
	}

	getSubSpecialityForSpeciality(specId: any) {
		let params = { specialityIds: specId };
		this.amplizService.getSubSpecialityForSpeciality(params).subscribe((res: any) => {
			this.c_subSpecialityList = res.subspecialityList;
		});
	}

	action_ageRangeList(action: any) {
		switch (action) {
			case 'fetch':
				this.tempDialogFilters.ageRangeList = this.filterStorageService.get('physician_age') || [];
				break;
			case 'store':
				this.filterStorageService.set('physician_age', this.tempDialogFilters.ageRangeList);
				break;
			case 'clear':
				this.filterStorageService.set('physician_age', []);
				break;
		}
	}

	action_classificationSpeciality(action: any) {
		switch (action) {
			case 'fetch':
				this.tempDialogFilters.specialityGroup =
					this.filterStorageService.get('c_specialityGroup') || '';
				this.tempDialogFilters.speciality = this.filterStorageService.get('c_speciality') || [];
				this.tempDialogFilters.subSpeciality = this.filterStorageService.get('c_subSpeciality') || [];
				this.c_subSpecialityList = this.filterStorageService.get('c_subSpecialityList') || [];
				if (this.tempDialogFilters.specialityGroup.length > 0) {
					let index = 0;
					index = this.c_specialityGroup.findIndex(
						(ele) => ele.specialityType == this.tempDialogFilters.specialityGroup
					);
					this.getSpecialityOfSpecialityGroup(this.c_specialityGroup[index].id);
				}
				break;
			case 'store':
				this.filterStorageService.set('c_specialityGroup', this.tempDialogFilters.specialityGroup);
				this.filterStorageService.set('c_speciality', this.tempDialogFilters.speciality);
				this.filterStorageService.set('c_subSpeciality', this.tempDialogFilters.subSpeciality);
				this.filterStorageService.set('c_subSpecialityList', this.c_subSpecialityList);

				this.filterStorageService.set('classificationSpeciality', [
					...this.tempDialogFilters.speciality,
					...this.tempDialogFilters.subSpeciality,
					this.tempDialogFilters.specialityGroup
				]);
				break;
			case 'clear':
				this.filterStorageService.set('c_specialityGroup', []);
				this.filterStorageService.set('c_speciality', []);
				this.filterStorageService.set('c_subSpeciality', []);
				this.filterStorageService.set('c_subSpecialityList', []);

				this.filterStorageService.set('classificationSpeciality', []);
				break;
		}
	}

	action_language(action: any, searchPhrase?: any) {
		switch (action) {
			case 'fetch':
				this.tempDialogFilters.languages = this.filterStorageService.get('physician_language') || [];
				break;
			case 'store':
				this.filterStorageService.set('physician_language', this.tempDialogFilters.languages);
				break;
			case 'clear':
				this.filterStorageService.set('physician_language', []);
				break;
			case 'api':
				this.amplizService.getLanguageList(searchPhrase).subscribe((res) => {
					this.languageList = res.languageList;
				});
				break;
		}
	}

	action_advanceFilter(action: any) {
		switch (action) {
			case 'fetch':
				this.tempDialogFilters.provider_Type =
					this.filterStorageService.get('physician_providerType') || '';
				this.tempDialogFilters.emailTypeIsp =
					this.filterStorageService.get('physician_emailTypeIsp') || '';
				this.d_privatePractioner =
					this.tempDialogFilters.provider_Type === 'Independent' ? true : false;
				this.d_personalEmail = this.tempDialogFilters.emailTypeIsp === 'Yes' ? true : false;
				this.toggleFilterLength();
				break;
			case 'store':
				this.filterStorageService.set('physician_emailTypeIsp', this.tempDialogFilters.emailTypeIsp);
				this.filterStorageService.set('physician_providerType', this.tempDialogFilters.provider_Type);
				break;
			case 'clear':
				this.filterStorageService.set('physician_emailTypeIsp', '');
				this.filterStorageService.set('physician_providerType', '');
				this.d_personalEmail = false;
				this.d_privatePractioner = false;
				break;
		}
	}

	action_emailStatus(action: any) {
		switch (action) {
			case 'fetch':
				this.tempDialogFilters.email_Score = this.filterStorageService.get('emailStatus') || 0;
				break;
			case 'store':
				this.filterStorageService.set('emailStatus', this.tempDialogFilters.email_Score);
				break;
			case 'clear':
				this.filterStorageService.set('emailStatus', 0);
				break;
		}
	}

	action_expirence(action: any) {
		switch (action) {
			case 'fetch':
				this.tempDialogFilters.experience = this.filterStorageService.get('physician_experience') || [];
				break;
			case 'store':
				this.filterStorageService.set('physician_experience', this.tempDialogFilters.experience);
				break;
			case 'clear':
				this.filterStorageService.set('physician_experience', []);
				break;
		}
	}

	action_procedureCodes(action: any) {
		switch (action) {
			case 'fetch':
				this.tempDialogFilters.hcpcsCodes =
					this.filterStorageService.get('physician_selectedHSCPCCode') || [];
				this.tempDialogFilters.cptCodes =
					this.filterStorageService.get('physician_selectedCPTCode') || [];
				break;
			case 'store':
				this.filterStorageService.set('physician_selectedCPTCode', this.tempDialogFilters.cptCodes);
				this.filterStorageService.set('physician_selectedHSCPCCode', this.tempDialogFilters.hcpcsCodes);
				break;
			case 'clear':
				this.filterStorageService.set('physician_selectedCPTCode', []);
				this.filterStorageService.set('physician_selectedHSCPCCode', []);
				break;
		}
	}

	getEHRProductName(searchPhrase: any) {
		this.amplizService.getEhrProductName(searchPhrase).subscribe((res) => {
			this.ehrProductNameList = res.ehremrproductnameList;
		});
	}

	action_teleHealth(action: any) {
		switch (action) {
			case 'alterValue':
				this.tempDialogFilters.istelehealth = this.d_teleHealth == true ? 'Y' : '';
				this.getRecordCount();
				break;

			case 'fetch':
				this.tempDialogFilters.istelehealth =
					this.filterStorageService.get('physician_isTeleHealth') || '';
				this.d_teleHealth = this.tempDialogFilters.istelehealth === 'Y' ? true : false;
				break;

			case 'store':
				this.filterStorageService.set('physician_isTeleHealth', this.tempDialogFilters.istelehealth);
				break;

			case 'clear':
				this.filterStorageService.set('physician_isTeleHealth', '');
				this.tempDialogFilters.istelehealth = '';
				this.d_teleHealth = false;
				this.getRecordCount();
				break;
		}
	}

	action_zipCodeByRadius(action: any) {
		switch (action) {
			case 'fetch':
				if (this.filterStorageService.get('physician_zipCodeByRadius') !== null) {
					const { selectedZipCode, radius, zipCodeList } = this.filterStorageService.get(
						'physician_zipCodeByRadius'
					);
					this.selectedZipCodeForRadius = selectedZipCode;
					this.selectedRadius = radius;
					this.tempDialogFilters.radiusZipcodes = zipCodeList;
				}
				break;
			case 'store':
				const zipCodeByRadius = {
					selectedZipCode: this.selectedZipCodeForRadius,
					radius: this.selectedRadius,
					zipCodeList: this.tempDialogFilters.radiusZipcodes
				};
				this.filterStorageService.set('physician_zipCodeByRadius', zipCodeByRadius);
				break;
			case 'clear':
				this.selectedZipCodeForRadius = '';
				this.tempDialogFilters.radiusZipcodes = [];
				this.getRecordCount();
				break;
		}
	}
	action_education(action: any) {
		switch (action) {
			case 'fetch':
				if (this.filterStorageService.get('physician_education') !== null) {
					const { residencyhospitals, medicalschools, fellowships } =
						this.filterStorageService.get('physician_education');
					this.tempDialogFilters.residencyhospitals = residencyhospitals;
					this.tempDialogFilters.medicalschools = medicalschools;
					this.tempDialogFilters.fellowships = fellowships;
				}

				break;
			case 'store':
				const education = {
					residencyhospitals: this.tempDialogFilters.residencyhospitals,
					medicalschools: this.tempDialogFilters.medicalschools,
					fellowships: this.tempDialogFilters.fellowships
				};
				this.filterStorageService.set('physician_education', education);
				break;
			case 'clear':
				this.tempDialogFilters.residencyhospitals = [];
				this.tempDialogFilters.fellowships = [];
				this.tempDialogFilters.medicalschools = [];
				this.getRecordCount();
		}
	}

	clearMoreFilterHospital() {
		this.tempDialogFilters.hospitalNameList = [];
		this.tempDialogFilters.classification = [];
		this.tempDialogFilters.firmtype = '';
		this.getRecordCount();
	}

	getZipCodeForRadius() {
		if (this.selectedZipCodeForRadius != '') {
			if (this.selectedZipCodeForRadius.length >= 5) {
				const params = {
					zipCode: this.selectedZipCodeForRadius,
					radius: this.selectedRadius
				};
				this.amplizService.getZipForRadius(params).subscribe(
					(res) => {
						this.tempDialogFilters.radiusZipcodes = res.zip_codes;
						this.getRecordCount();
					},
					(err) => {
						// console.log('err', err);
					}
				);
			} else {
				this.tempDialogFilters.radiusZipcodes = [this.selectedZipCodeForRadius];
				this.getRecordCount();
			}
		} else {
			this.tempDialogFilters.radiusZipcodes = [];
			this.getRecordCount();
		}
	}

	getEducationFilterList(filterType: string, searchPhrase: string) {
		if (searchPhrase.length > 3) {
			switch (filterType) {
				case 'residencyhospitals':
					this.amplizService.getListForResidencyHospitals(searchPhrase).subscribe((res: any) => {
						this.filteredResidencyHospitalList = res.residencyHospitalList;
					});
					break;
				case 'fellowships':
					this.amplizService.getListForFellowship(searchPhrase).subscribe((res: any) => {
						this.filteredFellowshipList = res.fellowshipHospitalList;
					});
					break;
				case 'medicalschools':
					this.amplizService.getListForMedicalSchools(searchPhrase).subscribe((res: any) => {
						this.filteredMedicalSchoolsList = res.medicalSchoolList;
					});
					break;
			}
		}
	}

	selectedEducationFilter(filterType: string, selectedValue: any) {
		const found = this.tempDialogFilters[filterType].findIndex((ele) => ele.name === selectedValue);
		if (found === -1) {
			this.tempDialogFilters[filterType].push({ name: selectedValue });
		}
		this.getRecordCount();
	}

	removeEducationFilter(filterType: string, removedValue: any) {
		this.tempDialogFilters[filterType] = this.tempDialogFilters[filterType].filter(
			(item) => item.name !== removedValue
		);
		this.getRecordCount();
	}

	getStateList(searchPhrase: string) {
		if (searchPhrase.length > 2) {
			this.amplizService.getStateList({ searchPhase: searchPhrase }).subscribe((response) => {
				this.educationStateList = response.stateDataList;
			});
		}
	}
	selectEducationState(filterType, value) {
		this.tempDialogFilters[filterType] = value;
		this.getRecordCount();
	}

	actionEducationLocation(action) {
		switch (action) {
			case 'clear':
				this.tempDialogFilters.undergraduate_state = [];
				this.tempDialogFilters.medicalschool_state = [];
				this.tempDialogFilters.residency_state = [];
				this.tempDialogFilters.fellowship_state = [];
				this.getRecordCount();
				break;
			case 'store':
				const educationLocation = {
					undergraduate_state: this.tempDialogFilters.undergraduate_state,
					medicalschool_state: this.tempDialogFilters.medicalschool_state,
					residency_state: this.tempDialogFilters.residency_state,
					fellowship_state: this.tempDialogFilters.fellowship_state
				};
				this.filterStorageService.set('physician_educationLocation', educationLocation);
				break;
			case 'fetch':
				if (this.filterStorageService.get('physician_educationLocation') !== null) {
					const { undergraduate_state, medicalschool_state, residency_state, fellowship_state } =
						this.filterStorageService.get('physician_educationLocation');

					this.tempDialogFilters.undergraduate_state = undergraduate_state;
					this.tempDialogFilters.medicalschool_state = medicalschool_state;
					this.tempDialogFilters.residency_state = residency_state;
					this.tempDialogFilters.fellowship_state = fellowship_state;
				}
		}
	}
	saveSearchClicked(searchName: string) {
		this.onSaveSearch.emit(searchName);
	}
	saveFilterApplied(filters: any) {
		this.hospitalNames = filters.hospitalNameList;
		this.includedSpecialities = filters.specialityIncluded;
		this.excludedSpecialities = filters.specialityExcluded;
		this.physicianName = filters.physicianName;
		this.filterData.cityList = filters.cityList;
		this.filterData.stateList = filters.stateList;
		this.npiNumbers = filters.npiNumber;
		this.leadWithEmail = filters.leadWithEmail;
		this.age = filters.age;
		this.tempDialogFilters.experience = filters.experience;
		this.tempDialogFilters.cptCodes = filters.cptCodes.map(String);
		this.tempDialogFilters.hcpcsCodes = filters.hcpcsCodes.map(String);
		this.tempDialogFilters.specialityGroup = filters.specialityGroup;
		this.tempDialogFilters.speciality = filters.speciality;
		this.tempDialogFilters.subSpeciality = filters.subSpeciality;
		// speciality = [...this.tempDialogFilters.speciality.map((item) => item.speciality)],
		// subSpeciality = [...this.tempDialogFilters.subSpeciality.map((item) => item)],
		this.tempDialogFilters.ageRangeList = filters.ageRangeList;
		this.tempDialogFilters.languages = filters.languages;
		this.tempDialogFilters.emailTypeIsp = filters.emailTypeIsp;
		this.tempDialogFilters.email_Score = filters.email_Score;
		this.tempDialogFilters.provider_Type = filters.provider_Type;
		this.tempDialogFilters.bsn = filters.bsn;
		this.tempDialogFilters.abmsabps = filters.abmsabps;
		this.tempDialogFilters.abom = filters.abom;
		this.tempDialogFilters.istelehealth = filters.istelehealth;

		this.filterData.firmtype = filters.firmtype;
		this.filterData.classification = filters.classification;
		this.tempDialogFilters.ehrproductname = filters.ehrproductname;
		this.tempDialogFilters.productclassification = filters.productclassification;
		this.tempDialogFilters.totalclaimsrange = filters.totalclaimsrange;
		this.filterData.zipcode = filters.zipCode;
		this.tempDialogFilters.radiusZipcodes = filters.radiusZipcodes;
		this.tempDialogFilters.residencyhospitals = filters.residencyhospitals;
		this.tempDialogFilters.medicalschools = filters.medicalschools;
		this.tempDialogFilters.fellowships = filters.fellowships;
		this.tempDialogFilters.licenses_state = filters.licenses_state;
		this.tempDialogFilters.undergraduate_state = filters.undergraduate_state;
		this.tempDialogFilters.medicalschool_state = filters.medicalschool_state;
		this.tempDialogFilters.residency_state = filters.residency_state;
		this.tempDialogFilters.fellowship_state = filters.fellowship_state;

		this.getRecordCount();
		this.omitChange();
		this.storeFilterData();
	}

	isFiltersApplied() {
		return (
			this.hospitalNames.length > 0 ||
			this.includedSpecialities.length > 0 ||
			this.excludedSpecialities.length > 0 ||
			this.physicianName.length > 0 ||
			this.filterData.cityList.length > 0 ||
			this.filterData.stateList.length > 0 ||
			this.npiNumbers.length > 0 ||
			this.leadWithEmail == true ||
			this.age.length > 0 ||
			this.tempDialogFilters.experience.length > 0 ||
			this.tempDialogFilters.cptCodes.length > 0 ||
			this.tempDialogFilters.hcpcsCodes.length > 0 ||
			this.tempDialogFilters.specialityGroup.length > 0 ||
			this.tempDialogFilters.speciality.length > 0 ||
			this.tempDialogFilters.subSpeciality.length > 0 ||
			// speciality.length > 0 ||
			// subSpeciality.length > 0 ||
			this.tempDialogFilters.ageRangeList.length > 0 ||
			this.tempDialogFilters.languages.length > 0 ||
			this.tempDialogFilters.emailTypeIsp.length > 0 ||
			this.tempDialogFilters.email_Score.length > 0 ||
			this.tempDialogFilters.provider_Type.length > 0 ||
			this.tempDialogFilters.bsn.length > 0 ||
			this.tempDialogFilters.abmsabps.length > 0 ||
			this.tempDialogFilters.abom.length > 0 ||
			this.tempDialogFilters.istelehealth.length > 0 ||
			this.filterData.firmtype.length > 0 ||
			this.filterData.classification.length > 0 ||
			this.tempDialogFilters.ehrproductname.length > 0 ||
			this.tempDialogFilters.productclassification.length > 0 ||
			this.tempDialogFilters.totalclaimsrange.length > 0 ||
			this.filterData.zipcode.length > 0 ||
			this.tempDialogFilters.radiusZipcodes.length > 0 ||
			this.tempDialogFilters.residencyhospitals.length > 0 ||
			this.tempDialogFilters.medicalschools.length > 0 ||
			this.tempDialogFilters.fellowships.length > 0 ||
			this.tempDialogFilters.licenses_state.length > 0 ||
			this.tempDialogFilters.undergraduate_state.length > 0 ||
			this.tempDialogFilters.medicalschool_state.length > 0 ||
			this.tempDialogFilters.residency_state.length > 0 ||
			this.tempDialogFilters.fellowship_state.length > 0
		);
	}

	// onKeywordChange(value) {
	// 	this.ampl
	// 	console.log('IN FUNC', value);
	// }
}
