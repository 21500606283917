import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
	selector: 'app-refresh-data-download',
	template: `
		<button
			type="button"
			(click)="btnClickedHandler()"
			class="btn btn-primary pr-3 pl-3"
			style="width: 100%; border-radius: 4px !important; background: #0c5a88 !important; color: #fff !important"
		>
			Download Updates
		</button>
	`,
	styles: []
})
export class RefreshDataDownloadComponent implements OnInit {
	public params: any;
	constructor() {}
	agInit(params: any): void {
		this.params = params;
		//
	}
	ngOnInit(): void {}

	btnClickedHandler() {
		this.params.clicked(this.params);
	}
}
