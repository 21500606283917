import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';

@Injectable({
	providedIn: 'root'
})
export class AdminScreenGuard implements CanActivate {
	constructor(private router: Router) {}
	allowedEmails: string[] = ['tanay123@gmail.com', 'subbu@ampliz.com'];
	canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		if (this.allowedEmails.indexOf(localStorage.getItem('email_id')) > -1) {
			return true;
		} else {
			this.router.navigate(['/login']);
			return false;
		}
	}
}
