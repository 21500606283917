<div id="wrapper">
	<app-header [user]="user" [elementName]="'lists'" style="width: 100%"></app-header>{{ user }}
	<!-- <app-header *ngIf="!subscribed" [header-data]="headerData"></app-header> -->
	<!-- <app-sidemenu elementName="lists"></app-sidemenu> -->

	<app-createlist
		[createListDiv]="createDrawer"
		(cancelBtnClick)="cancelBtnClick($event)"
		*ngIf="createDrawer"
	></app-createlist>

	<app-crm-panel [show]="crmExpanded" (panelClosed)="crmExpanded = false"></app-crm-panel>
	<!-- <app-footer></app-footer> -->

	<div class="content-page enlarged">
		<!-- Start content -->

		<div class="content mx-auto">
			<!-- container -->
			<div class="row">
				<div class="col">
					<div class="float-start">
						<div class="h5 text-dark mt-3 fw-normal">Lists</div>
					</div>
				</div>
				<div class="col">
					<div class="float-end mt-3">
						<div class="right-btn-container d-flex justify-content-end align-items-center">
							<!-- <a	
								class="mr-2"
								style="color: #0c5a88; font-size: 12px; font-weight: 400;"
								target="_blank"
								href="https://www.ampliz.com/talk-to-us?utm_source=ampliz&utm_medium=healthcare&utm_campaign=downloadupdates"
								>Need help?</a
							>
							<div class="integration-container" *ngIf="showIntegrations" (click)="crmExpanded = true">
								<button class="integration-btn">
									<mat-icon>manage_accounts</mat-icon>
									Manage CRM Integrations
								</button>
							</div>
							<div *ngIf="!isParaAuthenitcated">
								<button
									class="integration-btn inactive-integration-btn"
									matTooltip="You'll need to log in again to access CRM"
									[matTooltipPosition]="'left'"
								>
									CRM Inactive
								</button>
							</div>
							<div class="integration-container" *ngIf="showCrmRequest">
								<button class="integration-btn" (click)="requestCRM()">
									<mat-icon>manage_accounts</mat-icon>
									Request CRM Integration
								</button>
							</div> -->

							<div class="create-list-container ml-2">
								<button
									type="button"
									class="btn btn-lg create-list pull-right"
									(click)="createDrawer = true"
								>
									<mat-icon>add</mat-icon>
									Create List
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
			<!-- Begin page -->
			<div id="wrapper" style="margin-top: 20px">
				<!-- Start content -->

				<div class="container-fluid">
					<!-- Start Edit profile row -->
					<div class="row">
						<div class="table-wrapper">
							<div class="search_pagination_wrapper">
								<div class="row py-2 px-4 align-items-center">
									<div class="col-3">
										<!-- <button
											class="integration-btn"
											#trigger="matMenuTrigger"
											[matMenuTriggerFor]="crmExportMenu"
											(menuClosed)="setCRMMenu(false)"
											(menuOpened)="setCRMMenu(true)"
											*ngIf="showIntegrations"
										>
											CRM Export
											<mat-icon>cloud_upload</mat-icon>
										</button> -->
									</div>
									<div class="col-md-9 col-sm-12">
										<div class="row align-items-center justify-content-end">
											<div class="search-container col-4">
												<input
													type="text"
													id="filter-text-box"
													(keyup)="onFilterTextBoxChanged($event)"
													class="form-control form-control-lg"
													style="height: 40px"
													placeholder="Search"
													[(ngModel)]="searchString"
												/>
											</div>
											<div class="col-3">
												<div class="paginationWrapper">
													<div class="paginationWrapper" *ngIf="totalSize > 0">
														<ul *ngIf="pager.pages && pager.pages.length" class="pagination">
															<li [ngClass]="{ disabled: pager.currentPage === 1 }">
																<a *ngIf="pager.currentPage === 1" style="border-radius: 5px 0 0 5px !important"
																	><i class="fa fa-chevron-left" aria-hidden="true"></i
																></a>
																<a
																	*ngIf="pager.currentPage !== 1"
																	(click)="setPage(pager.currentPage - 1)"
																	style="border-radius: 5px 0 0 5px !important"
																	><i class="fa fa fa-chevron-left" aria-hidden="true"></i
																></a>
															</li>

															<li
																[ngClass]="{
																	disabled: pager.currentPage === pager.totalPages
																}"
															>
																<a
																	*ngIf="pager.currentPage === pager.totalPages"
																	style="border-radius: 0 5px 5px 0 !important"
																	><i class="fa fa-chevron-right" aria-hidden="true"></i
																></a>
																<a
																	*ngIf="pager.currentPage !== pager.totalPages"
																	(click)="setPage(pager.currentPage + 1)"
																	style="border-radius: 0 5px 5px 0 !important"
																	><i class="fa fa-chevron-right" aria-hidden="true"></i
																></a>
															</li>
														</ul>
														<div class="pull-right paginationInfo">
															{{ offset + 1 }}-{{ offset + count > totalSize ? totalSize : count + offset }} of
															{{ totalSize }}
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div style="display: flex; flex-direction: row; height: 100%">
									<div style="overflow: hidden; flex-grow: 1">
										<ag-grid-angular
											style="width: 100%; height: calc(100vh - 250px)"
											class="ag-theme-material list1"
											(gridReady)="onGridReady($event)"
											#agGrid
											[defaultColDef]="defaultColDef"
											[columnDefs]="columnDefs"
											[animateRows]="true"
											[sortingOrder]="sortingOrders"
											(firstDataRendered)="onFirstDataRendered($event)"
											[paginationPageSize]="paginationPageSize"
											[frameworkComponents]="frameworkComponents"
											(rowSelected)="onRowSelect()"
										>
										</ag-grid-angular>
									</div>
								</div>
							</div>
							<!-- <div style="display: flex; flex-direction: row; height: 100%">
                <div style="overflow: hidden; flex-grow: 1">
                  <ag-grid-angular
                    style="width: 100%; height: calc(100vh - 350px)"
                    class="ag-theme-material"
                    (gridReady)="onGridReady($event)"
                    #agGrid
                    [defaultColDef]="defaultColDef"
                    [columnDefs]="columnDefs"
                    [animateRows]="true"
                    (cellClicked)="onCellClicked($event)"
                    [sortingOrder]="sortingOrders"
                    (firstDataRendered)="onFirstDataRendered($event)"
                    [paginationPageSize]="paginationPageSize"
                    (columnResized)="onColumnResized($event)"
                  >
                    >
                  </ag-grid-angular>
                </div>
              </div> -->
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div class="confirm-dialog">
		<div class="confirm-dialog-overlay" [class.show-confirm-dialog]="showConfirmDialog">
			<div class="confirm-dialog-content">
				<div class="header-content">
					<span>Caution</span>
					<mat-icon class="close-icon" (click)="showConfirmDialog = false">close</mat-icon>
				</div>

				<div class="main-content">
					<span>Are you sure want to delete this List?</span>
				</div>

				<div class="action-content">
					<button class="cancel-btn" (click)="showConfirmDialog = false">Cancel</button>
					<button class="save-btn ml-2" (click)="deleteList()">Delete</button>
				</div>
			</div>
		</div>
	</div>

	<!-- <div class="modal fade" id="confirmDelete" role="dialog">
		<div class="modal-dialog modal-sm cancel-modal">
			<div class="modal-content cancel-modal">
				<form #confirmDelete>
					<div class="modal-header">
						<h6 class="modal-title"><i class="fa fa-times" aria-hidden="true"></i> Confirmation!</h6>
						<button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
					</div>
					<div class="modal-body">Are you sure want to delete this List?</div>
					<div class="modal-footer" style="display: block; overflow: hidden">
						<button type="button" class="btn btn-default btn-sm pull-left" data-dismiss="modal" #hideCancelOption>
							No
						</button>

						<button type="button" class="btn btn-danger btn-sm pull-right" (click)="deleteList()">Yes</button>
					</div>
				</form>
			</div>
		</div>
	</div> -->
</div>

<ng-template #requestMobileDialog let-data>
	<div class="d-flex flex-column h-100 p-0 justify-content-between">
		<div class="d-flex py-2 justify-content-between">
			<span>Number of Contacts</span>
			<mat-icon
				style="transform: scale(0.8); cursor: pointer"
				(click)="dialogRef.close(); requestMobileCount = 0"
				>close</mat-icon
			>
		</div>
		<input min="0" type="number" [(ngModel)]="requestMobileCount" placeholder="Count" />
		<div>
			<div class="d-grid gap-2">
				<div class="py-2">
					<span style="font-size: 11px; color: gray; font-weight: 400"
						>Please make sure the count is equal to or less than the <br />
						number of leads are in the list.</span
					>
				</div>
				<button
					type="button"
					(click)="requestMobileContact(data, requestMobileCount)"
					[disabled]="requestMobileCount <= 0 || requestMobileCount > data.noOfLeads"
					class="btn btn-sm btn-block btn-primary"
				>
					Request Mobile Numbers
				</button>
			</div>
		</div>
	</div>
</ng-template>

<mat-menu #crmExportMenu="matMenu" class="sf-menu">
	<div class="menu-wrapper row" (click)="$event.stopPropagation()">
		<app-list-integration-menu
			*ngIf="isCRMMenu"
			[listDetails]="selectedListDetails"
			(clearSelectedValue)="clearSelectedList()"
		>
		</app-list-integration-menu>
	</div>
</mat-menu>
