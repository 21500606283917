import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MessageService } from 'src/app/modules/B2B/services/message.service';
import { AmplizService } from '../../services/ampliz.service';
import { MatDialog } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
@Component({
	selector: 'app-admin-screen',
	templateUrl: './admin-screen.component.html',
	styleUrls: ['./admin-screen.component.css']
})
export class AdminScreenComponent implements OnInit {
	@ViewChild('userDialog') userDialog = {} as TemplateRef<any>;
	@ViewChild(MatPaginator) paginator!: MatPaginator;
	editForm!: FormGroup;
	createForm!: FormGroup;
	displayedColumns: string[] = [
		'name',
		'companyName',
		'currentCredit',
		'mobileCredit',
		'subscriptionType',
		'active',
		'action'
	];
	dataSource = new MatTableDataSource<any>([]);

	dialogRef: any;
	data: any;

	pager: any = { currentPage: 1, offset: 10, totalPages: 1 };
	totalCount: number = 0;

	editUserId: string = '';
	btnLoader: boolean = false;

	constructor(
		private amplizService: AmplizService,
		public dialog: MatDialog,
		private fb: FormBuilder,
		private messageService: MessageService
	) {}

	ngOnInit() {
		this.getUserList();
	}

	get offset() {
		const total = this.pager.offset * (this.pager.currentPage - 1);
		return +total + 1;
	}
	get count() {
		const pageCount = this.pager.currentPage * this.pager.offset;
		return this.pager.currentPage === this.pager.totalPages
			? this.totalCount
			: pageCount > this.totalCount
			? this.totalCount
			: pageCount;
	}

	onPaginationClick(currentPage) {
		this.pager = { ...this.pager, currentPage };
		this.getUserList();
	}

	private initializeForm(userData?: any): void {
		if (userData) {
			this.editForm = this.fb.group({
				fullname: [userData?.fullname, Validators.required],
				companyName: [userData?.companyName, Validators.required],
				phoneNumber: [userData?.phoneNumber, Validators.required],
				subscriptionType: [userData?.subscriptions?.subscriptionType, Validators.required],
				currentCredit: [userData?.currentCredits, Validators.required],
				mobileCredit: [userData?.mobilecredits, Validators.required],
				isActive: [userData?.subscriptions?.isActive, Validators.required],
				subscriptionName: [userData?.subscriptions?.subscriptionName, Validators.required],
				subscriptionCredits: [userData?.subscriptions?.subscriptionCredits, Validators.required],
				subscriptionMobileCredits: [userData?.subscriptions?.mobileCredits, Validators.required],
				subscriptionIsActive: [userData?.subscriptions?.isActive, Validators.required]
			});
		} else {
			this.createForm = this.fb.group({
				fullname: ['', Validators.required],
				password: ['', Validators.required],
				companyName: ['', Validators.required],
				email: ['', [Validators.required, Validators.email]],
				phoneNumber: ['', Validators.required],
				currentCredit: [0, Validators.required],
				mobileCredit: [0, Validators.required],
				isActive: [true, Validators.required],
				isPaid: ['Free', Validators.required],
				subscriptionName: ['', Validators.required],
				subscriptionAmount: [0, Validators.required],
				subscriptionCredits: [0, Validators.required],
				subscriptionMobileCredits: [0, Validators.required],
				subscriptionIsActive: [true, Validators.required]
			});
		}
		this.openDialogBox(userData ? true : false);
	}

	onSubmit(isEditMode: boolean): void {
		if (isEditMode) {
			if (this.editForm.valid) {
				this.editUser();
			}
		} else {
			if (this.createForm.valid) {
				this.createUser();
			}
		}
	}

	handleDialog(isEdit?: boolean, userId?: string) {
		this.editUserId = userId || '';
		isEdit ? this.getUserDetail(userId) : this.initializeForm();
	}

	openDialogBox(isEditMode: boolean) {
		this.dialogRef = this.dialog.open(this.userDialog, {
			width: '1200px',
			height: 'auto',
			disableClose: true,
			data: {
				isEditMode
			}
		});
	}

	createUser() {
		this.btnLoader = true;
		const subscriptionObj = {
			subscriptionName: this.createForm.value.subscriptionName,
			subscriptionCredits: this.createForm.value.subscriptionCredits,
			subscriptionAmount: this.createForm.value.subscriptionAmount,
			isActive: this.createForm.value.subscriptionIsActive
		};
		const inputParams = {
			fullname: this.createForm.value.fullname,
			email: this.createForm.value.email,
			password: this.createForm.value.password,
			companyName: this.createForm.value.companyName,
			phoneNumber: this.createForm.value.phoneNumber.toString(),
			currentCredit: this.createForm.value.currentCredit,
			mobileCredit: this.createForm.value.mobileCredit,
			isActive: this.createForm.value.isActive,
			isPaid: this.createForm.value.isPaid,
			subscription: subscriptionObj
		};

		this.amplizService.createUserForAdmin(inputParams).subscribe(async (res: any) => {
			await this.getUserList();
			this.btnLoader = false;
			this.messageService.display(true, res.message);
			setTimeout(() => {
				this.dialogRef.close();
			}, 300);
		});
	}

	editUser() {
		this.btnLoader = true;
		const subscriptionObj = {
			subscriptionName: this.editForm.value.subscriptionName,
			subscriptionCredits: this.editForm.value.subscriptionCredits,
			subscriptionType: this.editForm.value.subscriptionType,
			mobileCredits: this.editForm.value.subscriptionMobileCredits,
			isActive: this.editForm.value.subscriptionIsActive
		};
		const inputParams = {
			userID: this.editUserId,
			fullName: this.editForm.value.fullname,
			companyName: this.editForm.value.companyName,
			phoneNumber: this.editForm.value.phoneNumber.toString(),
			currentCredit: this.editForm.value.currentCredit,
			mobileCredit: this.editForm.value.mobileCredit,
			isActive: this.editForm.value.isActive,
			Subscription: subscriptionObj
		};

		if (
			inputParams.currentCredit <= inputParams.Subscription.subscriptionCredits &&
			inputParams.mobileCredit <= inputParams.Subscription.mobileCredits
		) {
			this.amplizService.editUserForAdmin(inputParams).subscribe(
				async (res: any) => {
					await this.getUserList();
					this.btnLoader = false;
					this.messageService.display(true, res.message);
					setTimeout(() => {
						this.dialogRef.close();
					}, 300);
				},
				(err) => {
					this.btnLoader = false;
				}
			);
		} else {
			this.messageService.displayError(true, 'Credits should not exceed the subscription credits');
			this.btnLoader = false;
		}
	}

	getUserList() {
		const skip = (this.pager.currentPage - 1) * this.pager.offset;

		this.amplizService.getUserCreatedForAdmin(skip, this.pager.offset).subscribe((res: any) => {
			this.totalCount = res.totalCount;
			this.pager = {
				...this.pager,
				totalPages: Math.ceil(this.totalCount / this.pager.offset)
			};
			this.dataSource.data = res?.usersDetail;
		});
	}

	getUserDetail(userId: string) {
		this.amplizService.getUserDetailsForAdmin(userId).subscribe((res: any) => {
			this.initializeForm(res);
		});
	}

	onCancel() {
		this.dialogRef.close();
	}
}
